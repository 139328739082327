import axios from "axios";

const Axios = axios
const hostname = window && window.location && window.location.hostname

const mainURL = 'https://lbv-api.wuebuild.com/api'
const stagingURL = 'https://lbv-staging-api.wuebuild.com/api'
// const stagingURL = 'http://localhost:5200/api'

let adminUrl = mainURL

if (hostname) {
    if (hostname.includes('staging-lbv.wuebuild.com') || hostname.includes('beta-lbv.netlify.app')) {
      adminUrl = stagingURL
    } else if (hostname.includes('localbalivillas.com')) {
      adminUrl = mainURL
    } else if (hostname.includes('localhost')) {
      adminUrl = stagingURL
    } else {
      adminUrl = mainURL
    }
}

export const client = Axios.create({
  baseURL: adminUrl,
  headers: {
    'Content-Type': 'application/json',
    // 'x-access-token': getToken()
  },
  timeout: 10000,
});

export const errorValidation = (e) => {
  try {
    if (e.response.status === 405) { 
      // deleteUserInfo()
    //   Action("", "You've been logged out. Please login again.", () => {
    //     window.location.href = "/"
    //   })
    }
    if (e.response.status === 403) { 
      // deleteUserInfo()
    }
    console.log('here error', e.response)
    return { error: e.response.data.message };
  } catch (error) {
    // toast('There is problem with server connection', {
    //   toastId: "error-api",
    //   type: "error"
    // });
    // Confirm("", "There is problem with server connection")
    return { error: 'There is problem with server connection'}
  }
}

// export const login = async function (body) {
//   try {
//     let response = await client.post('/login', {
//       phoneNumber: body.phoneNumber || ""
//     });
//     if (response.data.statusCode !== 200) { throw response.data.data; }
//     let data = response.data.data;
//     return { data };
//   } catch (e) {
//     return { error: e.response.data.errorCode };
//   }
// }

// export const registerUser = async function(body) {
//   try {
//     let response = await client.post(`/register-user/${body._id}`, {
//       name: body.name || "",
//       phoneNumber: body.phoneNumber || "",
//       gender: body.gender || "", // Male
//     });
//     if (response.data.statusCode !== 200) { throw response.data.data; }
//     let data = response.data.data;
//     return { data };
//   } catch (e) {
//     return { error: e.response.data.errorCode };
//   }
// };

