import { client, errorValidation } from "./service";

export const getReviews = async function ({userId, propertyId}) {
    try {
        let response = await client.get(`/reviews/${propertyId}?userId=${userId || ''}`);
        if (response.data.statusCode !== 200) { throw response.data.data; }
        let data = response.data.data;
        return { data, error: null };
    } catch (e) {
        return { data: null, ...errorValidation(e) }
    }
}

export const submitReview = async function ({
    email,
    review,
    rating,
    propertyId,
}) {
  try {
    let response = await client.post(`/reviews/create`, {
        email: email || '',
        review: review || '',
        rating: rating || '',
        propertyId: propertyId || '',
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data, error: null };
  } catch (e) {
    // return { error: e.response.data.errorCode }
    console.log('dataResponse', e.response)
    console.log('dataResponse', e.response.data.data)
    return { data: e.response.data.data || null, ...errorValidation(e) }
  }
}

export const updateReview = async function ({
    _id,
    email,
    review,
    rating,
    propertyId,
}) {
    try {
        let response = await client.post(`/reviews/update`, {
            _id: _id || '',
            email: email || '',
            review: review || '',
            rating: rating || '',
            propertyId: propertyId || '',
        });
        if (response.data.statusCode !== 200) { throw response.data.data; }
        let data = response.data.data;
        return { data, error: null };
      } catch (e) {
        // return { error: e.response.data.errorCode }
        return { data: null, ...errorValidation(e) }
      }
}