import { client, errorValidation } from "./service";

export const submitOrder = async function (form) {
  try {
    let response = await client.post(`/booking/submit`, {
      ...form
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data, error: null };
  } catch (e) {
    // return { error: e.response.data.errorCode }
    return { data: null, ...errorValidation(e) }
  }
}

export const checkCouponService = async function ({
  couponCode,
  dates,
  total,
  propertyId
}) {
  try {
    let response = await client.post(`/booking/coupon-check`, {
      couponCode,
      dates,
      total,
      propertyId
    });
    if (response.data.statusCode !== 200) { throw response; }
    let data = response.data.data;
    return { data, error: null };
  } catch (e) {
    // return { error: e.response.data.errorCode }
    return { data: null, ...errorValidation(e) }
  }
}