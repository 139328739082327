export const amenitiesData = [
    // {
    //     "category": "Cooling",
    //     "amenities": [
    //         {
    //             "icon": 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/air-conditioner.png',
    //             "name": "Air Conditioning",
    //             "selected": false
    //         }
    //     ]
    // },
    // {
    //     category: "Bathroom",
    //     amenities: [
    //         {
    //             icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/water-heater.png',
    //             name: "Hot water",
    //             selected: false
    //         },
    //         {
    //             icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/bathtub.png',
    //             name: "Bathtub",
    //             selected: false
    //         },
    //         {
    //             icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/bidet.png',
    //             name: "Bidet",
    //             selected: false
    //         },
    //         {
    //             icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/hair-dryer.png',
    //             name: "Hair Dryer",
    //             selected: false
    //         },
    //         {
    //             icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/liquid-soap.png',
    //             name: "Shampoo",
    //             selected: false
    //         },
    //         {
    //             icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/foam.png',
    //             name: "Shower Gel",
    //             selected: false
    //         },
    //     ]
    // },
    // {
    //     category: "Bedroom",
    //     amenities: [
    //         {
    //             icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/wardrobe.png',
    //             name: "Clothing storage",
    //             selected: false
    //         },
    //         {
    //             icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/clothes-hanger.png',
    //             name: "Hangers",
    //             selected: false
    //         },
    //         {
    //             icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/steam-iron.png',
    //             name: "Iron",
    //             selected: false
    //         },
    //         {
    //             icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/window.png',
    //             name: "Room-darkening shades",
    //             selected: false
    //         },
    //         {
    //             icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/safe.png',
    //             name: "Safe",
    //             selected: false
    //         },
    //     ]
    // },
    // {
    //     category: "Entertainment",
    //     amenities: [
    //         {
    //             icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/billiards.png',
    //             name: "Pool table",
    //             selected: false
    //         },
    //         {
    //             icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/sound-system.png',
    //             name: "Sound system",
    //             selected: false
    //         },
    //         {
    //             icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/television.png',
    //             name: "TV",
    //             selected: false
    //         },
    //         {
    //             icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/netflix.png',
    //             name: "Netflix",
    //             selected: false
    //         },
    //         {
    //             icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/voice-assistant.png',
    //             name: "Alexa",
    //             selected: false
    //         },
    //         {
    //             icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/book.png',
    //             name: "Books and reading material",
    //             selected: false
    //         },
    //         {
    //             icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/free-wifi.png',
    //             name: "Wifi",
    //             selected: false
    //         },
    //     ]
    // },
    // {
    //     "category": "Kitchen and dining",
    //     "amenities": [
    //         {
    //             "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/air-conditioner.png",
    //             "name": "Bread maker",
    //             "selected": false
    //         },
    //         {
    //             "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/air-conditioner.png",
    //             "name": "Blender",
    //             "selected": false
    //         },
    //         {
    //             "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/air-conditioner.png",
    //             "name": "Coffee maker",
    //             "selected": false
    //         },
    //         {
    //             "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/air-conditioner.png",
    //             "name": "Cooking basics",
    //             "selected": false
    //         },
    //         {
    //             "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/air-conditioner.png",
    //             "name": "Dining table",
    //             "selected": false
    //         },
    //         {
    //             "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/air-conditioner.png",
    //             "name": "Dishes and silverware",
    //             "selected": false
    //         },
    //         {
    //             "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/air-conditioner.png",
    //             "name": "Hot water kettle",
    //             "selected": false
    //         },
    //         {
    //             "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/air-conditioner.png",
    //             "name": "Kitchen",
    //             "selected": false
    //         },
    //         {
    //             "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/air-conditioner.png",
    //             "name": "Kitchenette",
    //             "selected": false
    //         },
    //         {
    //             "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/air-conditioner.png",
    //             "name": "Microwave",
    //             "selected": false
    //         },
    //         {
    //             "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/air-conditioner.png",
    //             "name": "Mini Fridge",
    //             "selected": false
    //         },
    //         {
    //             "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/air-conditioner.png",
    //             "name": "Oven",
    //             "selected": false
    //         },
    //         {
    //             "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/air-conditioner.png",
    //             "name": "Refrigerator",
    //             "selected": false
    //         },
    //         {
    //             "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/air-conditioner.png",
    //             "name": "Rice Maker",
    //             "selected": false
    //         },
    //         {
    //             "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/air-conditioner.png",
    //             "name": "Stove",
    //             "selected": false
    //         },
    //         {
    //             "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/air-conditioner.png",
    //             "name": "Toaster",
    //             "selected": false
    //         },
    //         {
    //             "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/air-conditioner.png",
    //             "name": "Wine Glasses",
    //             "selected": false
    //         }
    //     ]
    // },
    {
        "category": "Heating and cooling",
        "amenities": [
            {
                "icon": 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/air-conditioner.png',
                "name": "Air Conditioning",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/ceiling-fan.png",
                "name": "Ceiling Fan",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/heat.png",
                "name": "Heating",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/fireplace.png",
                "name": "Indoor Fireplace",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/fan.png",
                "name": "Portable Fans",
                "selected": false
            }
        ]
    },
    {
        "category": "Bathroom",
        "amenities": [
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/bathtub.png",
                "name": "Bathtub",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/bidet.png",
                "name": "Bidet",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/cleansing.png",
                "name": "Body Soap",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/cosmetic.png",
                "name": "Cleaning Products",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/hair-conditioner.png",
                "name": "Conditioner",
                "selected": false
            },
            {
                "icon": 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/hair-dryer.png',
                "name": "Hair Dryer",
                "selected": false
            },
            {
                "icon": 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/water-heater.png',
                "name": "Hot water",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/outdoor-shower.png",
                "name": "Outdoor Shower",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/liquid-soap.png",
                "name": "Shampoo",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/foam.png",
                "name": "Shower Gel",
                "selected": false
            }
        ]
    },
    {
        "category": "Bedroom and laundry",
        "amenities": [
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/bed.png",
                "name": "Essentials",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/pillow.png",
                "name": "Bed linens",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/wardrobe.png",
                "name": "Clothing storage",
                "selected": false
            },
            // {
            //     "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/hairdryer.png",
            //     "name": "Dryer",
            //     "selected": false
            // },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/towel-rack.png",
                "name": "Drying rack for clothing",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/bedroom.png",
                "name": "Extra pillows and blankets",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/clothes-hanger.png",
                "name": "Hangers",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/steam-iron.png",
                "name": "Iron",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/mosquito.png",
                "name": "Mosquito net",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/window.png",
                "name": "Room-darkening shades",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/safe.png",
                "name": "Safe",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/washer.png",
                "name": "Washer",
                "selected": false
            }
        ]
    },
    {
        "category": "Entertainment",
        "amenities": [
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/arcade-game.png",
                "name": "Arcade games",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/player.png",
                "name": "Batting cage",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/bowling-ball.png",
                "name": "Bowling alley",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/climbing.png",
                "name": "Climbing wall",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/ethernet.png",
                "name": "Ethernet connection",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/dumbbell.png",
                "name": "Exercise equipment",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/game-console.png",
                "name": "Game console",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/laser-tag.png",
                "name": "Laser tag",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/board-game.png",
                "name": "Life size games",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/birdie.png",
                "name": "Mini golf",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/theater.png",
                "name": "Movie theater",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/piano.png",
                "name": "Piano",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/ping-pong.png",
                "name": "Ping pong table",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/vinyl.png",
                "name": "Record player",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/skateboard.png",
                "name": "Skate ramp",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/cartoons.png",
                "name": "Theme room",
                "selected": false
            },
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/billiards.png',
                name: "Pool table",
                selected: false
            },
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/sound-system.png',
                name: "Sound system",
                selected: false
            },
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/television.png',
                name: "TV",
                selected: false
            },
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/netflix.png',
                name: "Netflix",
                selected: false
            },
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/voice-assistant.png',
                name: "Alexa",
                selected: false
            },
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/book.png',
                name: "Books and reading material",
                selected: false
            },
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/projector.png',
                name: "Projector",
                selected: false
            },
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/free-wifi.png',
                name: "Wifi",
                selected: false
            },
        ]
    },
    {
        "category": "Family",
        "amenities": [
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/baby.png",
                "name": "Baby bath",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/baby-monitor.png",
                "name": "Baby monitor",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/baby-bike.png",
                "name": "Children’s bikes",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/playground.png",
                "name": "Children's playroom",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/gate.png",
                "name": "Baby safety gates",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/mother.png",
                "name": "Babysitter recommendations",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/board-game.png",
                "name": "Board games",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/changing-table.png",
                "name": "Changing table",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/toys.png",
                "name": "Children’s books and toys",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/meal.png",
                "name": "Children’s dinnerware",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/crib.png",
                "name": "Crib",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/fireplace.png",
                "name": "Fire screen",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/high-chair.png",
                "name": "High chair",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/love.png",
                "name": "Outdoor playground",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/outlet.png",
                "name": "Outlet covers",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/baby+(1).png",
                "name": "Pack ’n play/Travel crib",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/protector.png",
                "name": "Table corner guards",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/window.png",
                "name": "Window guards",
                "selected": false
            }
        ]
    },
    {
        category: "Home safety",
        amenities: [
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/technology.png',
                name: "Lock on bedroom door",
                selected: false
            },
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/smoke-detector.png',
                name: "Smoke alarm",
                selected: false
            },
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/first-aid-kit.png',
                name: "First aid kit",
                selected: false
            },
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/security.png',
                name: "Fire extinguisher",
                selected: false
            },
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/carbon-monoxide.png',
                name: "Carbon monoxide alarm",
                selected: false
            }
        ]
    },
    {
        "category": "Internet and office",
        "amenities": [
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/desk.png",
                "name": "Dedicated workspace",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/connection.png",
                "name": "Pocket wifi",
                "selected": false
            },
        ]
    },
    {
        category: "Kitchen and Dining",
        amenities: [
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/blender.png',
                name: "Blender",
                selected: false
            },
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/bread-maker.png',
                name: "Bread maker",
                selected: false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/sheet.png",
                "name": "Baking sheet",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/barbeque.png",
                "name": "Barbecue utensils",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/coffee-cup.png",
                "name": "Coffee",
                "selected": false
            },
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/coffee-machine.png',
                name: "Coffee maker",
                selected: false
            },
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/flambe.png',
                name: "Cooking basics",
                selected: false
            },
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/dinner-table.png',
                name: "Dining table",
                selected: false
            },
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/restaurant.png',
                name: "Dishes and silverware",
                selected: false
            },
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/kettle.png',
                name: "Hot water kettle",
                selected: false
            },
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/kitchen.png',
                name: "Kitchen",
                selected: false
            },
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/cookhouse.png',
                name: "Kitchenette",
                selected: false
            },
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/microwave-oven.png',
                name: "Microwave",
                selected: false
            },
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/minibar.png',
                name: "Mini Fridge",
                selected: false
            },
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/oven.png',
                name: "Oven",
                selected: false
            },
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/refrigerator.png',
                name: "Refrigerator",
                selected: false
            },
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/pressure-cooker.png',
                name: "Rice Maker",
                selected: false
            },
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/stove.png',
                name: "Stove",
                selected: false
            },
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/toaster.png',
                name: "Toaster",
                selected: false
            },
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/wine-glass.png',
                name: "Wine Glasses",
                selected: false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/air-conditioner.png",
                "name": "Dishwasher",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/freezer.png",
                "name": "Freezer",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/trash-compactor.png",
                "name": "Trash Compactor",
                "selected": false
            },
        ]
    },
    {
        "category": "Location features",
        "amenities": [
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/sunset.png",
                "name": "Beach access",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/lake.png",
                "name": "Lake access",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/laundry-shop.png",
                "name": "Laundromat nearby",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/no-entry.png",
                "name": "Private entrance",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/ticket.png",
                "name": "Resort access",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/alpine.png",
                "name": "Ski-in/Ski-out",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/mountain.png",
                "name": "Waterfront",
                "selected": false
            }
        ]
    },
    {
        "category": "Outdoor",
        "amenities": [
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/backyard.png",
                "name": "Backyard",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/grill.png",
                "name": "BBQ grill",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/vacations.png",
                "name": "Beach essentials",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/bike.png",
                "name": "Bikes",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/boat.png",
                "name": "Boat slip",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/bonfire.png",
                "name": "Fire pit",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/hammock.png",
                "name": "Hammock",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/kayak.png",
                "name": "Kayak",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/chairs.png",
                "name": "Outdoor dining area",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/sun-umbrella.png",
                "name": "Outdoor furniture",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/outdoor-kitchen.png",
                "name": "Outdoor kitchen",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/balcony.png",
                "name": "Patio or balcony",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/umbrella.png",
                "name": "Sun loungers",
                "selected": false
            }
        ]
    },
    {
        "category": "Parking and facilities",
        "amenities": [
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/parking.png",
                "name": "Free parking on premises",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/parking.png",
                "name": "Free street parking",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/parking.png",
                "name": "Paid parking on premises",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/parking.png",
                "name": "Paid parking off premises",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/elevator.png",
                "name": "Elevator",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/charging-station.png",
                "name": "EV charger",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/ice.png",
                "name": "Hockey rink",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/gym.png",
                "name": "Gym",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/hot-bath.png",
                "name": "Hot tub",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/swimming-pool.png",
                "name": "Pool",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/interior-design.png",
                "name": "Private living room",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/sauna.png",
                "name": "Sauna",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/building.png",
                "name": "Single level home",
                "selected": false
            }
        ]
    },
    {
        category: "Smoking",
        amenities: [
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/no-smoking.png',
                name: "No Smoking",
                selected: false
            }
        ]
    },
    {
        "category": "Services",
        "amenities": [
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/english-breakfast.png",
                "name": "Breakfast",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/cleaning.png",
                "name": "Cleaning available during stay",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/calendar.png",
                "name": "Long term stays allowed",
                "selected": false
            },
            {
                "icon": "https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/luggage.png",
                "name": "Luggage dropoff allowed",
                "selected": false
            },
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/gardener.png',
                name: "Gardener",
                selected: false
            },
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/maid.png',
                name: "Housekeeping",
                selected: false
            },
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/concierge.png',
                name: "Concierge 24 hours",
                selected: false
            },
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/washing.png',
                name: "Pool maintenance",
                selected: false
            },
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/policeman.png',
                name: "Security",
                selected: false
            },
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/servant-outline.png',
                name: "Butler",
                selected: false
            },
        ]
    },
    {
        category: "View",
        amenities: [
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/ocean-view.png',
                name: "Ocean view",
                selected: false
            },
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/beach.png',
                name: "Sea view",
                selected: false
            },
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/farm.png',
                name: "Rice field view",
                selected: false
            },
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/mountain.png',
                name: "Mountain view",
                selected: false
            },
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/creek.png',
                name: "River view",
                selected: false
            },
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/forest.png',
                name: "Forest view",
                selected: false
            },
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/valley.png',
                name: "Valley view",
                selected: false
            },
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/park.png',
                name: "Garden view",
                selected: false
            },
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/fireworks.png',
                name: "City view",
                selected: false
            },
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/cliff.png',
                name: "Clifftop",
                selected: false
            },
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/vacations.png',
                name: "Beachfront",
                selected: false
            },
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/swimming-pool.png',
                name: "Pool View",
                selected: false
            },
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/forest.png',
                name: "Jungle View",
                selected: false
            },
            {
                icon: 'https://sstaging-localbalivilla.s3.ap-southeast-1.amazonaws.com/lbv_amenities_icon/apartment.png',
                name: "Non View",
                selected: false
            }
        ]
    },
]