import React, { useEffect, useState } from "react"
import { Button, Dialog, Grid } from "@mui/material"
import { LBVLabel, LBVTitleLabel } from "../label/LBVLabel"
import { LBVInput } from "./LBVInput"
import LBVCalendar from "../booking/form/Calendar"

import CalendarIcon from '@mui/icons-material/CalendarMonth';
import { Card } from "shards-react"
import { isPhone } from "../../helper/constant"
import DialogHeader from "../dialog/DialogHeader"

import moment from "moment";
import { calculateDays } from "../../helper/form"

function LBVDateInput ({
    showLabel = false,
    setDateInfo = (e) => {},
    isOpenDetail = false,
    defaultDateRange = null,
    onClick = null,
    price = null,
    setPrice = false,
    placeData = null,
    disabledDates = null
}) {

    const [ dateRange, setDateRange ] = useState({
        startDate: null,
        endDate: null,
        days: [],
        totalDays: 0
    })
    const [ showDetail, setShowDetail ] = useState(isOpenDetail)

    useEffect(() => {
        setShowDetail(isOpenDetail)
    }, [isOpenDetail])

    useEffect(() => {
        setDateInfo(dateRange)
    }, [dateRange])

    useEffect(() => {
        console.log('dateInfo -1 ', defaultDateRange)
        if (defaultDateRange && (defaultDateRange.startDate && defaultDateRange.endDate)) { setDateRange(defaultDateRange) }
    }, [defaultDateRange])

    return (
        <Grid container>
            {
                showLabel &&
                <Grid item xs={12}>
                    <LBVTitleLabel>
                        Select Check-in Date
                    </LBVTitleLabel>
                </Grid>
            }
            <Grid item container xs={12}>
                <Grid item container onClick={(e) => {
                    if (onClick) { onClick() }
                    else { setShowDetail(true) }
                }}>
                    <Grid item container p={1} xs sx={{
                        background: '#f8fafc',
                        borderRadius: 2,
                        borderTopRightRadius: 1,
                        borderBottomRightRadius: 1
                    }}>
                        <Grid item mt={0.5} mr={1}>
                            <CalendarIcon sx={{
                                color: '#957c64'
                            }}/>
                        </Grid>
                        <Grid item container xs>
                            <Grid item xs>
                                <LBVInput placeHolder="Check-in Date" value={
                                    (dateRange.startDate && dateRange.endDate) ? 
                                    `${moment(dateRange.startDate).format("DD/MM/YYYY")} - ${moment(dateRange.endDate).format("DD/MM/YYYY")}` : null
                                } />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {
                    (showDetail && !isPhone()) &&
                    <CustomPopup 
                        toggle={() => {
                            setShowDetail((prev) => false)
                        }}
                        style={{
                            maxWidth: '45em'
                        }}
                    >
                        <Grid container>
                            <Grid item xs={12}>
                                <LBVCalendar price={price} disabledDates={disabledDates} onChange={(e) => {
                                    console.log('here date info', e)
                                    let date = e.length > 0 ? e[0] : null
                                    if (date) {
                                        let totalDays = calculateDays({
                                            startDate: date.startDate,
                                            endDate: date.endDate
                                        })
                                        setDateRange(prev => ({
                                            ...prev,
                                            startDate: date.startDate,
                                            endDate: date.endDate,
                                            days: totalDays,
                                            totalDays: totalDays.length
                                        }))
                                    }
                                }} />
                            </Grid>
                            <Grid item container xs={12} mb={2}>
                                <Grid item xs ml={2} textAlign={"left"}>
                                    {
                                        price && <LBVLabel subtitle>* All rates are listed in IDR</LBVLabel>
                                    }
                                </Grid>
                                <Grid item xs mr={2} textAlign={"right"}>
                                    <Button variant="contained"
                                        onClick={() => {
                                            setShowDetail((prev) => false)
                                        }}>Save</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CustomPopup>
                }
                {
                    (showDetail && isPhone()) &&
                    <Dialog open={showDetail} fullScreen={true}>
                        <Grid p={2}>
                            <DialogHeader title={"Select Check-in Date"} closeDialog={() => {
                                setShowDetail(false)
                            }}/>
                        </Grid>
                        <Grid sx={{
                            height: '100%'
                        }}>
                            <Grid item p={0} xs={12} textAlign={"center"}>
                                <LBVCalendar month={1} price={price} disabledDates={disabledDates} onChange={(e) => {
                                    console.log('here date info', e)
                                    let date = e.length > 0 ? e[0] : null
                                    if (date) {
                                        let totalDays = calculateDays({
                                            startDate: date.startDate,
                                            endDate: date.endDate
                                        })
                                        setDateRange(prev => ({
                                            ...prev,
                                            startDate: date.startDate,
                                            endDate: date.endDate,
                                            days: totalDays,
                                            totalDays: totalDays.length
                                        }))
                                    }
                                }} />
                            </Grid>
                            {/* <Grid item mt={0} pt={0} mr={6} xs={12} textAlign={"right"}>
                                <Button variant="contained"
                                    onClick={() => {
                                        setShowDetail(false)
                                    }}
                                >Save</Button>
                            </Grid> */}
                            <Grid item container xs={12} mb={2}>
                                <Grid item xs ml={2} textAlign={"left"}>
                                    {
                                        price && <LBVLabel subtitle>* All rates are listed in IDR</LBVLabel>
                                    }
                                </Grid>
                                <Grid item xs mr={2} textAlign={"right"}>
                                    <Button variant="contained"
                                        onClick={() => {
                                            setShowDetail((prev) => false)
                                        }}>Save</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Dialog>
                }
            </Grid>
        </Grid>
    )
}

function CustomPopup ({
    toggle, children, style
}) {

    return (
        <Grid p={2} sx={{
            position: 'absolute',
            marginTop: 6,
            zIndex: 100,
            ...style
        }}>
            <div style={{
                position: 'fixed',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0
            }} onClick={toggle}/>
            <Card style={{
                padding: 10
            }}>
            { children }
            </Card>
        </Grid>
    )

}

export default LBVDateInput