import { Button, Grid } from "@mui/material";
import { LBVLabel, LBVTitleLabel } from "../../label/LBVLabel";
import moment from "moment";
import { Card } from "shards-react";
import { Info } from "@mui/icons-material";
import { color } from "../../../helper/constant";

import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import PoolIcon from '@mui/icons-material/Pool';

import lbvIconAdult from "../../../assets/lbv_icon_adult.png";
import lbvIconKids from "../../../assets/lbv_icon_kids.png";
import lbvIconPool from "../../../assets/lbv_icon_pool.png";
import lbvIconRoom from "../../../assets/lbv_icon_room.png";

function MobileRoomTable ({
    data,
    generateAmenitiesList,
    bookingData,
    calculatePrice,
    bookButtonClick,
    setShowRoomDetail,
    setOpenBottomSheet,
    checkRoomAvailbility
}) {

    return (
        <Grid container xs={12}>
            {
                data.map( value => {   
                    return (
                        <Grid item xs={12} mb={2}>
                            <Card style={{padding: 10}}>
                                <Grid container>
                                    <Grid item xs={12} onClick={() => {
                                        setShowRoomDetail({
                                            ...value,
                                            propertyImage: value.propertyImage.length > 0 ? value.propertyImage : data.propertyImage
                                        })
                                    }}>
                                        {
                                            value.propertyImage &&
                                            <div style={{
                                                marginTop: -8,
                                                marginLeft: -8,
                                                marginRight: -8,
                                                marginBottom: 8
                                            }}><img style={{
                                                borderTopLeftRadius: 8,
                                                borderTopRightRadius: 8,
                                                objectFit: 'cover'
                                            }} src={value.propertyImage[0]} width={'100%'} height={180}/></div>
                                        }
                                        <LBVTitleLabel>
                                            <u>{value.name}</u> <LBVLabel>{value.room} Rooms</LBVLabel>
                                        </LBVTitleLabel>
                                        <Grid container xs pt={0.5} pb={0.5} spacing={1}>
                                        {
                                            value.maximumGuest && 
                                            <Grid item>
                                                {
                                                    (value.maximumGuest.adult > 0) && 
                                                    <LBVLabel><img width={20} height={20} src={lbvIconAdult} />{value.maximumGuest.adult}</LBVLabel>
                                                }
                                                {
                                                    (value.maximumGuest.child > 0) && 
                                                    <LBVLabel><img width={20} height={20} src={lbvIconKids} />{value.maximumGuest.child}</LBVLabel>
                                                }
                                            </Grid>
                                        }
                                        {
                                            value.poolSize && 
                                            <Grid item>
                                                <LBVLabel><img width={20} height={20} src={lbvIconPool} />{value.poolSize}</LBVLabel>
                                            </Grid>
                                        }
                                        {
                                            value.roomSize && 
                                            <Grid item>
                                                <LBVLabel><img width={20} height={20} src={lbvIconRoom} />{value.roomSize}</LBVLabel>
                                            </Grid>
                                        }
                                        </Grid>
                                        {
                                            value.amenities.length > 0 && 
                                            <Grid container xs={12} spacing={0.5} mt={0.5}>
                                                {generateAmenitiesList(value.amenities)}
                                            </Grid>
                                        }
                                        {/* <Grid mt={1}><LBVLabel>View Detail</LBVLabel></Grid> */}
                                        {/* <Grid mt={1}>
                                            <LBVLabel subtitle style={{
                                                color: color.primary                                                        
                                            }} onClick={() => {
                                                setShowRoomDetail({
                                                    ...value
                                                })
                                            }}><u>View detail</u></LBVLabel>
                                        </Grid> */}
                                    </Grid>
                                    <Grid item container alignItems={"flex-end"} textAlign={"left"}>
                                        {
                                            !checkRoomAvailbility(value) &&
                                            <Grid item xs={9}>
                                            {
                                                (bookingData.startDate && bookingData.endDate) &&
                                                <Grid item container spacing={1} paddingTop={1} paddingBottom={1}>
                                                    <Grid item >
                                                        <LBVLabel>Booking Date</LBVLabel>
                                                    </Grid>
                                                    <Grid item >
                                                        <LBVLabel>{bookingData.days.length <= 1 ? `${moment(bookingData.startDate).format('DD/MM/YYYY')}` : `${
                                                            moment(bookingData.days[0]).format('DD/MM/YYYY')
                                                        } - ${moment(bookingData.days[bookingData.days.length - 1]).format('DD/MM/YYYY')}` }</LBVLabel>
                                                    </Grid>
                                                </Grid>
                                            }
                                            <Grid item container>
                                                <Grid item xs={12} alignItems={"left"} justifyItems={"left"}>
                                                    <Grid item>
                                                        <LBVLabel style={{
                                                            fontSize: 16
                                                        }} bold>{calculatePrice(value).value} 
                                                        {/* <span>
                                                            {value.facilities.airportShuttle && <Info style={{
                                                                marginTop: -2,
                                                                color: color.primary,
                                                                fontSize: 20
                                                            }}
                                                                onClick={() => {
                                                                    setOpenBottomSheet(true)
                                                                }}
                                                            />}
                                                        </span> */}
                                                        <LBVLabel style={{marginLeft: 5}}>x {bookingData.rooms} Room{bookingData.rooms > 1 ? 's' : ''}</LBVLabel>
                                                        </LBVLabel>
                                                    </Grid>
                                                    {/* <Grid item>
                                                        <LBVLabel>x {bookingData.rooms} Room{bookingData.rooms > 1 ? 's' : ''}</LBVLabel>
                                                    </Grid> */}
                                                    <Grid item xs={12}>
                                                        <Grid xs={12} mb={0.5}>
                                                            {/* <Tooltip title={
                                                                <LBVLabel style={{ color: 'white', fontWeight: 200, fontSize: 12 }}>Here hello world</LBVLabel>
                                                            }>
                                                                <LBVLabel>No Refund nor Modify</LBVLabel>
                                                                <Info style={{
                                                                    color: color.primary
                                                                }}/>
                                                            </Tooltip> */}
                                                            <MoneyOffIcon fontSize="small"/>
                                                                <LBVLabel style={{
                                                                    marginLeft: 5
                                                                }}>No Refund nor Modify</LBVLabel>
                                                                <Info style={{
                                                                    color: color.primary
                                                                }} onClick={() => {
                                                                    setOpenBottomSheet({
                                                                        open: true,
                                                                        information: "This reservation cannot be cancelled nor modified (Non refundable). "
                                                                    })
                                                                }}/>
                                                        </Grid>
                                                        {
                                                            value.facilities && (value.facilities.airportShuttle == true) &&
                                                            <Grid xs={12} mb={0.5}>
                                                                {/* <Tooltip title={
                                                                    <LBVLabel style={{ color: 'white', fontWeight: 200, fontSize: 12 }}>Extra additional charge for airport shuttle</LBVLabel>
                                                                }>
                                                                    <LBVLabel>Airport Shuttle</LBVLabel>
                                                                    <Info style={{
                                                                        color: color.primary
                                                                    }}/>
                                                                </Tooltip> */}
                                                                <AirportShuttleIcon fontSize="small"/>
                                                                <LBVLabel style={{
                                                                    marginLeft: 5
                                                                }}>Airport Shuttle</LBVLabel>
                                                                <Info style={{
                                                                    color: color.primary
                                                                }} onClick={() => {
                                                                    setOpenBottomSheet({
                                                                        open: true,
                                                                        information: "Extra additional charge for airport shuttle"
                                                                    })
                                                                }}/>
                                                            </Grid>
                                                        }
                                                        {
                                                            value.facilities && (value.facilities.breakfast == true) &&
                                                            <Grid xs={12} mb={0.5}>
                                                                <RestaurantIcon fontSize="small"/>
                                                                <LBVLabel style={{
                                                                    marginLeft: 5
                                                                }}>Breakfast</LBVLabel>
                                                            </Grid>
                                                        }
                                                        {
                                                            value.facilities && (value.facilities.freeParking == true) &&
                                                            <Grid xs={12} mb={0.5}>
                                                                <LocalParkingIcon fontSize="small"/>
                                                                <LBVLabel>Free Parking</LBVLabel>
                                                            </Grid>
                                                        }
                                                        {
                                                            value.facilities && (value.facilities.privatePool == true) &&
                                                            <Grid xs={12} mb={0.5}>
                                                                <PoolIcon fontSize="small"/>
                                                                <LBVLabel>Private Pool</LBVLabel>
                                                            </Grid>
                                                        }
                                                        {
                                                            value.facilities && (value.facilities.publicPool == true) &&
                                                            <Grid xs={12} mb={0.5}>
                                                                <PoolIcon fontSize="small"/>
                                                                <LBVLabel>Public Pool</LBVLabel>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        }
                                        {
                                            !checkRoomAvailbility(value) &&
                                            <Grid item xs={3} textAlign={"right"}>
                                                <Button variant="contained"
                                                    disabled={checkRoomAvailbility(value)}
                                                    onClick={() => { bookButtonClick({value}) }}
                                                >Book</Button>
                                            </Grid>
                                        }
                                        <Grid item xs={12} style={{marginTop: 12}} textAlign={"left"}>
                                        {
                                            checkRoomAvailbility(value) &&
                                            <LBVLabel style={{fontSize: 14}}>*Room not available in selected dates</LBVLabel>
                                        }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    )
                })
            }
        </Grid>
    )
}

export default MobileRoomTable