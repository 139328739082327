import React, { useEffect, useState } from "react";
import { Button, Dialog, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@mui/material";
import { LBVLabel, LBVTitleLabel } from "../label/LBVLabel";
import { currencyFormat } from "../../helper/numberFormat";
import moment from "moment";
import { LBVInput } from "../input/LBVInput";
import DialogHeader from "../dialog/DialogHeader";
import OrderSummary from "../../pages/Order/OrderSummary";
import { amenitiesData } from "../../helper/amenities";
import { color, isPhone } from "../../helper/constant";
import MobileRoomTable from "./mobile/MobileRoomTable";

import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { Info, Pool } from "@mui/icons-material";

import lbvTick from "../../assets/lbv_tick.gif";
import { Modal } from "shards-react";

import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import PoolIcon from '@mui/icons-material/Pool';

import lbvIconAdult from "../../assets/lbv_icon_adult.png";
import lbvIconKids from "../../assets/lbv_icon_kids.png";
import lbvIconPool from "../../assets/lbv_icon_pool.png";
import lbvIconRoom from "../../assets/lbv_icon_room.png";

function RoomTable ({
    data,
    // dateInfo,
    // guestInfo,
    // roomInfo,
    formInfo,
    showCalendar,
    setShowRoomDetail
}) {

    const [ bookingData, setBookingData ] = useState({
        roomId: null,
        roomName: "",
        startDate: null,
        endDate: null,
        days: [],
        totalDays: 0,
        rooms: 1
    })

    const [ openBottomSheet, setOpenBottomSheet ] = useState({
        open: false,
        information: ''
    })
    const [ showBookingSummary, setShowBookingSummary ] = useState(false)
    const [ showConfirmation, setShowConfirmation ] = useState({
        url: "",
        open: false
    })
    const [ showDialog, setShowDialog ] = useState({
        open: false,
        information: ""
    })
    const [ roomList, setRoomList ] = useState([])

    useEffect(() => {}, [bookingData])

    useEffect(() => {
        if (data && data.roomType) {
            let newRoomData = []
            for (let i = 0 ; i < data.roomType.length; i ++) {
                let isDisable = checkRoomAvailbility(data.roomType[i])
                newRoomData.push({
                    ...data.roomType[i],
                    position: isDisable ? 1 : 0
                })
            }
            newRoomData.sort((a, b) => a.position - b.position || a.price - b.price)
            setRoomList(newRoomData)
        }
    }, [data, bookingData])

    useEffect(() => {
        if (formInfo) {
            setBookingData(prev => ({
                ...prev,
                ...formInfo.dateInfo,
                ...formInfo
            }))
        }
    }, [formInfo])

    const checkRoomAvailbility = (data) => {
        // setDisableDate
        let disableDate = false
        if (bookingData && bookingData.days) {
            for (let i = 0; i < bookingData.days.length - 1; i ++) {
                if (data.disabledDate.indexOf(bookingData.days[i]) >= 0) {
                    disableDate = true
                    break;
                }
            }
        }
        return disableDate
    }

    const calculatePrice = (value) => {
        if (!value) { 
            return {
                value: currencyFormat(0),
                priceList: []
            }
        }
        let totalPrice = 0
        let priceInfo = []
        for (let i = 0; i < bookingData.days.length - 1; i ++) {
            let day = bookingData.days[i]
            let dateSpecialPrice = false
            // check priceList
            if (value.priceList.length > 0) {
                let specialPrice = value.price
                for (let j = 0; j < value.priceList.length; j ++) {
                    let dateList = value.priceList[j].date
                    if (!dateList) { continue; }
                    if (dateList.indexOf(day) >= 0) {
                        specialPrice = value.priceList[j].price
                        dateSpecialPrice = true
                        break;
                    }
                    let dayList = value.priceList[j].day
                    if (!dayList) { continue; }
                    if (dayList && dayList.length > 0 &&
                        dayList.indexOf(moment(day).format('dddd')) >= 0) {
                        if (!dateSpecialPrice) { specialPrice = value.priceList[j].price }
                    }
                    // if ()
                }
                priceInfo.push({
                    date: day,
                    price: specialPrice
                })
                totalPrice += specialPrice
            } else {
                priceInfo.push({
                    date: day,
                    price: value.price
                })
                totalPrice += value.price
            }
        }
        totalPrice = totalPrice * bookingData.rooms
        return {
            value: currencyFormat(!totalPrice ? value.price : totalPrice),
            totalPrice,
            priceList: priceInfo
        } 
    }

    const generateAmenitiesList = (value) => {
        let component = []
        for (let i = 0; i < amenitiesData.length; i ++) {
            let amenities = amenitiesData[i].amenities
            if (component.length >= 4) { break; }
            for (let j = 0; j < amenities.length; j++) {
                if (component.length >= 4) { break; }
                let name = amenities[j].name
                if (value.indexOf(name) >= 0) {
                    component.push(
                        <Grid item>
                            <Grid container p={0.5} sx={{
                                border: 'solid 1px #957c64',
                                borderRadius: 2,
                                fontSize: 11,
                                cursor: 'pointer',
                            }} justifyItems={"center"} alignItems={"center"}>
                                {amenities[j].icon && <img src={amenities[j].icon} width={12} height={12} style={{marginRight: 2}}/>}
                                {amenities[j].name}
                            </Grid>
                        </Grid>
                    )
                }
            }
        }
        return component
    }

    return (
        <>
        {
            isPhone() ?
            <MobileRoomTable 
                data={roomList}
                bookingData={bookingData}
                calculatePrice={calculatePrice}
                generateAmenitiesList={generateAmenitiesList}
                bookButtonClick={(e) => {
                    let { value } = e
                    setBookingData(prev => ({
                        ...prev,
                        roomId: value._id,
                        roomName: value.name,
                        roomDetail: value
                    }))
                    if (bookingData.days.length > 0) { setShowBookingSummary(true) }
                    else {
                        showCalendar(true)
                    }
                }}
                setShowRoomDetail={setShowRoomDetail}
                setOpenBottomSheet={setOpenBottomSheet}
                checkRoomAvailbility={checkRoomAvailbility}
            />
            :
            <Grid container xs={12} mt={1}>
                <Paper sx={{
                    width: '100%',
                    overflowX: 'auto'
                }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Accomodation Type</TableCell>
                                <TableCell align="left">Info</TableCell>
                                <TableCell align="left">Price</TableCell>
                                <TableCell align="left">Room</TableCell>
                                <TableCell align="left"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                roomList.map( value => {   
                                    return (
                                        <TableRow style={{ cursor: 'pointer' }}>
                                            <TableCell sx={{ width: 300}}>
                                                {
                                                    value.propertyImage &&
                                                    <div><img src={value.propertyImage[0]} width={'80%'} height={'auto'}/></div>
                                                }
                                                <LBVTitleLabel onClick={() => {
                                                    setShowRoomDetail({
                                                        ...value,
                                                        propertyImage: value.propertyImage.length > 0 ? value.propertyImage : data.propertyImage
                                                    })
                                                }}><u>{value.name}</u></LBVTitleLabel>
                                                {
                                                    value.amenities.length > 0 && 
                                                    <Grid container spacing={0.5} mt={0.5}>
                                                        {generateAmenitiesList(value.amenities)}
                                                    </Grid>
                                                }
                                            </TableCell>
                                            <TableCell sx={{ width: 150}}>
                                                {
                                                    value.room &&
                                                    <Grid xs={12}>
                                                        <LBVLabel>{value.room} Room{value.room > 1 ? 's' : ''}</LBVLabel>
                                                    </Grid>
                                                }
                                                {
                                                    value.maximumGuest && 
                                                    <Grid xs={12} mt={1}>
                                                        <Tooltip title={`Maximum Guest`}>
                                                            <div>
                                                            {
                                                                (value.maximumGuest.adult > 0) && 
                                                                <LBVLabel><img width={20} height={20} src={lbvIconAdult} />{value.maximumGuest.adult}</LBVLabel>
                                                            }
                                                            {
                                                                (value.maximumGuest.child > 0) && 
                                                                <LBVLabel><img width={20} height={20} src={lbvIconKids} />{value.maximumGuest.child}</LBVLabel>
                                                            }
                                                            </div>
                                                        </Tooltip>
                                                    </Grid>
                                                }
                                                {
                                                    value.poolSize && 
                                                    <Grid xs={12} mt={1}>
                                                        <Tooltip title={"Pool Size"}>
                                                            <div>
                                                                <LBVLabel><img width={20} height={20} src={lbvIconPool} />{value.poolSize}</LBVLabel>
                                                            </div>
                                                        </Tooltip>
                                                    </Grid>
                                                }
                                                {
                                                    value.roomSize && 
                                                    <Grid xs={12} mt={1}>
                                                        <Tooltip title={`Room / Villa Size`}>
                                                            <div>
                                                                <LBVLabel><img width={20} height={20} src={lbvIconRoom} />{value.roomSize}</LBVLabel>
                                                            </div>
                                                        </Tooltip>
                                                    </Grid>
                                                }
                                            </TableCell>
                                            <TableCell sx={{ width: 200}}>
                                                {
                                                    (bookingData.startDate && bookingData.endDate) &&
                                                    <Grid>
                                                        <LBVLabel subtitle>{bookingData.days.length <= 1 ? `${moment(bookingData.startDate).format('DD/MM/YYYY')}` : `${
                                                            moment(bookingData.days[0]).format('DD/MM/YYYY')
                                                        } - ${moment(bookingData.days[bookingData.days.length - 1]).format('DD/MM/YYYY')}` }</LBVLabel>
                                                    </Grid>
                                                }
                                                <Grid item container>
                                                    <Grid item xs>
                                                        <LBVLabel bold style={{marginRight: 2}}>{calculatePrice(value).value}</LBVLabel>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                            <TableCell sx={{ width: 80}}>
                                                <LBVInput disabled={true} value={bookingData.rooms}/>
                                            </TableCell>
                                            <TableCell sx={{ width: 350}}>
                                                {
                                                    !checkRoomAvailbility(value) &&
                                                    <Grid p={2} container>
                                                        <Grid item xs={12}>
                                                            <LBVLabel>{bookingData.rooms || 1} Room for</LBVLabel>
                                                        </Grid>
                                                        <LBVTitleLabel style={{ color: 'black' }}>{calculatePrice(value).value}</LBVTitleLabel>
                                                    </Grid>
                                                }
                                                {
                                                    !checkRoomAvailbility(value) &&
                                                    <Button variant="contained" 
                                                        disabled={checkRoomAvailbility(value)}
                                                        style={{
                                                            width: '100%',
                                                            minWidth: 40,
                                                            minHeight: 30,
                                                            borderRadius: 20
                                                        }}
                                                        onClick={() => {
                                                            setBookingData(prev => ({
                                                                ...prev,
                                                                roomId: value._id,
                                                                roomName: value.name,
                                                                roomDetail: value
                                                            }))
                                                            if (bookingData.days.length > 0) { setShowBookingSummary(true) }
                                                            else {
                                                                showCalendar(true)
                                                            }
                                                        }}
                                                    >
                                                        <LBVLabel inheritColor>Book</LBVLabel>
                                                    </Button>
                                                }
                                                {
                                                    checkRoomAvailbility(value) &&
                                                    <LBVLabel style={{marginLeft: 10, fontSize: 14}}>*Room not available in selected dates</LBVLabel>
                                                }
                                                {
                                                    !checkRoomAvailbility(value) &&
                                                    <Grid p={2}>
                                                        <Grid xs={12} mb={0.5}>
                                                            <MoneyOffIcon fontSize="small"/>
                                                            <LBVLabel style={{
                                                                marginLeft: 5
                                                            }}>No Refund nor Modify</LBVLabel>
                                                            <Info style={{
                                                                color: color.primary
                                                            }} onClick={() => {
                                                                setShowDialog({
                                                                    open: true,
                                                                    information: "This reservation cannot be cancelled nor modified (Non refundable)."
                                                                })
                                                            }}/>
                                                        </Grid>
                                                        {
                                                            value.facilities && (value.facilities.airportShuttle == true) &&
                                                            <Grid xs={12} mb={0.5}>
                                                                <AirportShuttleIcon fontSize="small"/>
                                                                <LBVLabel style={{
                                                                    marginLeft: 5
                                                                }}>Airport Shuttle</LBVLabel>
                                                                <Info style={{
                                                                    color: color.primary
                                                                }} onClick={() => {
                                                                    setShowDialog({
                                                                        open: true,
                                                                        information: "Extra additional charge for airport shuttle"
                                                                    })
                                                                }}/>
                                                            </Grid>
                                                        }
                                                        {
                                                            value.facilities && (value.facilities.breakfast == true) &&
                                                            <Grid xs={12} mb={0.5}>
                                                                <RestaurantIcon fontSize="small"/>
                                                                <LBVLabel style={{
                                                                    marginLeft: 5
                                                                }}>Breakfast</LBVLabel>
                                                            </Grid>
                                                        }
                                                        {
                                                            value.facilities && (value.facilities.freeParking == true) &&
                                                            <Grid xs={12} mb={0.5}>
                                                                <LocalParkingIcon fontSize="small"/>
                                                                <LBVLabel>Free Parking</LBVLabel>
                                                            </Grid>
                                                        }
                                                        {
                                                            value.facilities && (value.facilities.privatePool == true) &&
                                                            <Grid xs={12} mb={0.5}>
                                                                <PoolIcon fontSize="small"/>
                                                                <LBVLabel>Private Pool</LBVLabel>
                                                            </Grid>
                                                        }
                                                        {
                                                            value.facilities && (value.facilities.publicPool == true) &&
                                                            <Grid xs={12} mb={0.5}>
                                                                <PoolIcon fontSize="small"/>
                                                                <LBVLabel>Public Pool</LBVLabel>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </Paper>
            </Grid>
        }
        <Dialog 
            PaperProps={{
                sx: {
                    minWidth: '60vw',
                    minHeight: '90vh'
                }
            }}
            open={showBookingSummary} fullWidth={true} onClose={() => {
                setShowBookingSummary(false)
            }}>
            <Grid container p={2}>
                <Grid item xs={12} mb={2}>
                    <DialogHeader title={"Booking Summary"} closeDialog={() => {
                        setShowBookingSummary(false)
                    }}/>
                </Grid>
                <Grid item container>
                    <OrderSummary setShowBookingSummary={(e, url) => {
                        console.log('here url', url)
                        setShowBookingSummary(false)
                        if (url) { 
                            setShowConfirmation({
                                url : url,
                                open: true
                            })
                        }
                    }} bookingData={{
                        placeId: data._id,
                        placeName: data.name,
                        ...bookingData,
                        ...calculatePrice(bookingData.roomDetail)
                    }} />
                </Grid>
            </Grid>
        </Dialog>
        <Dialog PaperProps={{sx: { minWidth: '60vw' }}} open={showConfirmation.open} fullWidth={true}>
           <ConfirmationAlert url={showConfirmation.url}/> 
        </Dialog>
        <BottomSheet open={openBottomSheet.open} onDismiss={() => { setOpenBottomSheet({
            open: false,
            information: ''
        }) }}>
            <Grid p={2}>
                <Grid mt={2} mb={2}>
                    {openBottomSheet.information}
                </Grid>
            </Grid>
        </BottomSheet>
        <Modal open={showDialog.open} centered={true} toggle={() => {
            setShowDialog({
                open: false,
                information: ''
            })
        }}>
            <Grid p={2}>
                {showDialog.information}
            </Grid>
        </Modal>
        </>
    )
}

function ConfirmationAlert ({
    url
}) {

    const [ timer, setTimer ] = useState(5)

    useEffect(() => {
        let interval = null
        console.log('here url', url)
        if (url) {
            setTimeout(() => {
                let outsideLink = document.createElement('a');
                // outsideLink.target= '_blank';
                outsideLink.href= url;
                outsideLink.click();
            }, 5000);
        }
        interval = setInterval(() => {
            setTimer(prev => prev - 1)
        }, 1000);
        return () => clearInterval(interval);
    }, [])

    return (
        <Grid container p={2} pb={6} xs={12} textAlign={"center"}>
            <Grid item xs={12}>
                <img src={lbvTick} height={'120px'} width={'120px'}/>
            </Grid>
            <Grid item xs={12}>
                <Grid>
                    <LBVTitleLabel>Your booking is being proceed</LBVTitleLabel>
                </Grid>
                <Grid>
                    <LBVLabel style={{
                        color: color.primary
                    }}>Please don't close this page, you will be redirected to payment page in {(timer && timer >= 0) ? timer : 0} seconds. If you not redirected <a style={{
                        color: "#957c64",
                        textDecoration: 'underline'
                    }} href={url}>click here</a>.</LBVLabel>
                </Grid>
                <Grid>
                    <LBVLabel style={{
                        color: color.primary
                    }}>After payment confirmed, we will send your booking confirmation to your email.</LBVLabel>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default RoomTable