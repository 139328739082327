import React, { useState } from "react"
import DialogHeader from "./dialog/DialogHeader"
import { Dialog, Grid } from "@mui/material"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import LBVGallery from "./Lightbox";

function ListImage ({
    showImageModel,
    setShowImageModel,
    setOpenImageDetail,
    propertyImages
}) {

    const [ openImage, setOpenImage ] = useState(null)
    const [ imageList, setImageList ] = useState(propertyImages)

    return (
        <Grid p={2}>
            <DialogHeader title={"Image List"} closeDialog={() => {
                setShowImageModel(!showImageModel)
            }} />
            <Grid container xs={12}>
            {
                propertyImages.map( (value, index) => {
                    return (<Grid onClick={() => {
                        setOpenImage(index)
                        let image = [ ...propertyImages ]
                        for (let i = (propertyImages.length - 1); i >= index ; i --) {
                            image.unshift(propertyImages[i])
                        }
                        for (let i = 0; i < index; i ++) {
                            image.push(propertyImages[i])
                        }
                        setImageList(image)
                    }} item xs={12} lg={4} p={0.3} justifyContent={"center"}>
                        <img src={value} width={'100%'} height={'100%'} style={{objectFit: 'fill'}}/>
                    </Grid>)
                })
            }
            </Grid>
            {/* <Dialog open={openImage} onClose={() => {setOpenImage(null)}}>
                {
                    openImage &&
                    <TransformWrapper>
                        <TransformComponent>
                            <img width={'100%'} height={'100%'} src={openImage} />
                        </TransformComponent>
                    </TransformWrapper>
                }
            </Dialog> */}
            <LBVGallery
                open={(openImage != null && openImage >= 0)}
                setOpen={() => { setOpenImage(null) }}
                images={imageList}
            />
        </Grid>
    )
}

export default ListImage