import { client, errorValidation } from "./service";

export const getCoupons = async function () {
    try {
        let response = await client.get(`/coupon-list/`);
        if (response.data.statusCode !== 200) { throw response.data.data; }
        let data = response.data.data;
        return { data, error: null };
    } catch (e) {
        return { data: null, ...errorValidation(e) }
    }
}