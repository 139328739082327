import React, { useState } from "react";
import { LBVLabel, LBVTitleLabel } from "./label/LBVLabel";
import { Grid } from "@mui/material";
import { Container } from "shards-react";
import { isPhone } from "../helper/constant";
import { useWindowSize } from "../helper/windowResize";

import { FaEnvelope, FaFacebook, FaInstagram, FaLocationDot, FaPhone, FaTiktok, FaWhatsapp } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

import bankPayment from '../assets/bank_payment.png';
import lbvLogo from '../assets/lbv_logo.png';

export default function Footer () {

    const [ windowSize, setWindowSize ] = useState(useWindowSize())
    const navigate = useNavigate()

    return (
        <>
            {
                isPhone() ?
                <div style={{
                    left: 0,
                    right: 0,
                    bottom: 0,
                    paddingTop: 20,
                    paddingBottom: 10,
                    backgroundColor: '#957c6433',
                    textAlign: 'center',
                    marginTop: 20,
                }}>
                    <Grid container xs={12}>
                        <Grid item xs={12} lg={6} textAlign={"left"} p={2} pt={0}>
                            <LBVTitleLabel style={{
                                fontWeight: 400,
                            }}>About Local Bali</LBVTitleLabel>
                            <Grid>
                                <LBVLabel style={{
                                    fontSize: 11,
                                    fontWeight: 100
                                }}>
                                    Founded in 2020 in Bali, Currently, Local Bali Villas is a continuously growing new start-up company. Local Bali Villas’ mission is to make people around the world easier to find likewise their second home with the best service and become the unforgettable moments during their stay-cation in Bali.
                                    Local Bali Villas has been working together with more than thousands of the best villas in Bali for more than 10 years of experience in tourism. As foreseen, the booking service dilemma is commonly caused by a lack of miscommunication between both guests and the villas’ owner therefore we commit to minimize it to meet the guests’ expectations.
                                </LBVLabel>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container xs={12} mt={2}>
                        <div style={{
                            height: 1, 
                            width: '100%',
                            backgroundColor: '#0000001A'
                        }}></div>
                    </Grid>
                    <Grid container xs={12}>
                        <Grid xs={12} p={2}>
                            <LBVTitleLabel>
                                More Information
                            </LBVTitleLabel>
                        </Grid>
                        <Grid xs={6} p={2} pt={0}>
                            <Grid item xs={12} textAlign={"left"} pt={0} onClick={() => {
                                navigate('about-us/who-are-we')
                            }}>
                                <LBVLabel style={{
                                    fontWeight: 100,
                                    cursor: 'pointer'
                                }}>
                                    Who Are We
                                </LBVLabel>
                            </Grid>
                            <Grid item xs={12} textAlign={"left"} pt={0} onClick={() => {
                                navigate('about-us/why-book-with-us')
                            }}>
                                <LBVLabel style={{
                                    fontWeight: 100,
                                    cursor: 'pointer'
                                }}>
                                    Why Book with Us
                                </LBVLabel>
                            </Grid>
                            <Grid item xs={12} textAlign={"left"} pt={0} onClick={() => {
                                navigate('about-us/best-price-guarantee')
                            }}>
                                <LBVLabel style={{
                                    fontWeight: 100,
                                    cursor: 'pointer'
                                }}>
                                    Best Price Guarantee
                                </LBVLabel>
                            </Grid>
                            <Grid item xs={12} textAlign={"left"} pt={0} onClick={() => {
                                navigate('about-us/terms-condition')
                            }}>
                                <LBVLabel style={{
                                    fontWeight: 100,
                                    cursor: 'pointer'
                                }}>
                                    Terms and Conditions Apply
                                </LBVLabel>
                            </Grid>
                            <Grid item xs={12} textAlign={"left"} pt={0} onClick={() => {
                                navigate('about-us/faq')
                            }}>
                                <LBVLabel style={{
                                    fontWeight: 100,
                                    cursor: 'pointer'
                                }}>
                                    FAQ
                                </LBVLabel>
                            </Grid>
                            <Grid item xs={12} textAlign={"left"} pt={0} onClick={() => {
                                navigate('about-us/privacy-policy')
                            }}>
                                <LBVLabel style={{
                                    fontWeight: 100,
                                    cursor: 'pointer'
                                }}>
                                    Privacy Policy
                                </LBVLabel>
                            </Grid>
                        </Grid>
                        <Grid xs={6} p={2} pt={0}>
                            <Grid item xs={12} textAlign={"left"} pt={0} onClick={() => {
                                navigate('about-us/legal-information')
                            }}>
                                <LBVLabel style={{
                                    fontWeight: 100,
                                    cursor: 'pointer'
                                }}>
                                    Legal Information
                                </LBVLabel>
                            </Grid>
                            <Grid item xs={12} textAlign={"left"} pt={0} onClick={() => {
                                navigate('about-us/compliance-standard')
                            }}>
                                <LBVLabel style={{
                                    fontWeight: 100,
                                    cursor: 'pointer'
                                }}>
                                    Compliance and Standards
                                </LBVLabel>
                            </Grid>
                            <Grid item xs={12} textAlign={"left"} pt={0} onClick={() => {
                                navigate('about-us/client-protection')
                            }}>
                                <LBVLabel style={{
                                    fontWeight: 100,
                                    cursor: 'pointer'
                                }}>
                                    Client Protection
                                </LBVLabel>
                            </Grid>
                            <Grid item xs={12} textAlign={"left"} pt={0} onClick={() => {
                                navigate('about-us/contact-us')
                            }}>
                                <LBVLabel style={{
                                    fontWeight: 100,
                                    cursor: 'pointer'
                                }}>
                                    Contact Us
                                </LBVLabel>
                            </Grid>
                            <Grid item xs={12} textAlign={"left"} pt={0} onClick={() => {
                                navigate('about-us/24-7-support')
                            }}>
                                <LBVLabel style={{
                                    fontWeight: 100,
                                    cursor: 'pointer'
                                }}>
                                    24/7 Support Team
                                </LBVLabel>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container xs={12} p={2} pt={0}>
                        <Grid item xs={6}>
                            <Grid xs={12}>
                                <LBVTitleLabel>Follow Us</LBVTitleLabel>
                            </Grid>
                            <Grid xs={12}>
                                <FaInstagram style={{
                                    color: '#957c64',
                                    marginRight: 3,
                                    marginTop: -2,
                                    cursor: 'pointer',
                                    minWidth: 20
                                }} onClick={() => {
                                    window.open('https://www.instagram.com/localbalivillas/', '_blank')
                                }}/>
                                <FaFacebook style={{
                                    color: '#957c64',
                                    marginRight: 3,
                                    marginTop: -2,
                                    cursor: 'pointer',
                                    minWidth: 20
                                }} onClick={() => {
                                    window.open('https://www.facebook.com/localbalivillas', '_blank')
                                }}/>
                                <FaTiktok style={{
                                    color: '#957c64',
                                    marginRight: 3,
                                    marginTop: -2,
                                    cursor: 'pointer',
                                    minWidth: 20
                                }} onClick={() => {
                                    window.open('https://www.tiktok.com/@localbalivillas', '_blank')
                                }}/>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <img src={lbvLogo} width={60} height={60} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={4} mb={2}>
                        <LBVTitleLabel style={{
                            fontWeight: 400,
                        }}>Payment Accepted</LBVTitleLabel>
                        <Grid>
                            <img src={bankPayment} width={200} height={'auto'} />
                        </Grid>
                    </Grid>
                    {/* <Grid item xs={12} textAlign={"left"} p={2}>
                        <LBVTitleLabel style={{
                            fontWeight: 400,
                        }}>
                            Contact Us
                        </LBVTitleLabel>
                        <Grid item xs={12}>
                            <div style={{ display: 'flex' }} onClick={() => {
                                window.open("https://maps.google.com/?q=-8.666457170367105,115.22611985571564", "_blank")
                            }}>
                                <FaLocationDot style={{
                                    color: '#957c64',
                                    marginRight: 3,
                                    marginTop: 3,
                                    cursor: 'pointer',
                                    minWidth: 20
                                }}/>
                                <LBVLabel style={{
                                    fontWeight: 100,
                                    cursor: 'pointer'
                                }}>
                                    Jl. Cok Agung Tresna - Renon, Komplek Pertokoan Griya Alamanda No. 12 A lt1 Denpasar - Bali
                                </LBVLabel>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div onClick={() => {
                                window.location.href = "tel:+6282340243600"
                            }}>
                                <FaPhone style={{
                                    color: '#957c64',
                                    marginRight: 3,
                                    marginTop: -2,
                                    cursor: 'pointer',
                                    minWidth: 20
                                }} onClick={() => {
                                    
                                }}/>
                                <LBVLabel style={{
                                    fontWeight: 100,
                                    cursor: 'pointer'
                                }}>
                                    +62 823-4024-3600
                                </LBVLabel>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div onClick={() => {
                                window.open("https://api.whatsapp.com/send?phone=6282340243600", "_blank")
                            }}>
                                <FaWhatsapp fontSize={20} style={{
                                    color: '#957c64',
                                    marginRight: 3,
                                    marginTop: -2,
                                    cursor: 'pointer',
                                    minWidth: 20
                                }}/>
                                <LBVLabel style={{
                                    fontWeight: 100,
                                    cursor: 'pointer'
                                }}>
                                    +62 823-4024-3600
                                </LBVLabel>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div onClick={() => {
                                window.open('mailto:info@localbalivillas.com?subject=Subject&body=', "_blank")
                            }}>
                                <FaEnvelope style={{
                                    color: '#957c64',
                                    marginRight: 3,
                                    marginTop: -2,
                                    cursor: 'pointer',
                                    minWidth: 20
                                }}/>
                                <LBVLabel style={{
                                    fontWeight: 100,
                                    cursor: 'pointer'
                                }}>
                                    info@localbalivillas.com
                                </LBVLabel>
                            </div>
                        </Grid>
                        <Grid item xs={12} mt={1}>
                            <LBVTitleLabel style={{
                                fontWeight: 400,
                            }}>
                                    Connect with Us
                            </LBVTitleLabel>
                            <Grid xs={12}>
                                <FaInstagram style={{
                                    color: '#957c64',
                                    marginRight: 3,
                                    marginTop: -2,
                                    cursor: 'pointer',
                                    minWidth: 20
                                }} onClick={() => {
                                    window.open('https://www.instagram.com/localbalivillas', '_blank')
                                }}/>
                                <FaFacebook style={{
                                    color: '#957c64',
                                    marginRight: 3,
                                    marginTop: -2,
                                    cursor: 'pointer',
                                    minWidth: 20
                                }} onClick={() => {
                                    window.open('https://www.facebook.com/localbalivillas', '_blank')
                                }}/>
                                <FaTiktok style={{
                                    color: '#957c64',
                                    marginRight: 3,
                                    marginTop: -2,
                                    cursor: 'pointer',
                                    minWidth: 20
                                }} onClick={() => {
                                    window.open('https://www.tiktok.com/@localbalivillas', '_blank')
                                }}/>
                            </Grid>
                        </Grid>
                    </Grid> */}
                    {/* <Grid item xs={12} textAlign={"left"} p={2} pt={0}>
                        <LBVTitleLabel style={{
                            fontWeight: 400,
                        }}>
                            About Us
                        </LBVTitleLabel>
                        <Grid item container xs={12}>
                            <Grid item container xs={6}>
                                <Grid item xs={12} textAlign={"left"} pt={0} onClick={() => {
                                    navigate('about-us/who-are-we')
                                }}>
                                    <LBVLabel style={{
                                        fontWeight: 100,
                                        cursor: 'pointer'
                                    }}>
                                        Who Are We
                                    </LBVLabel>
                                </Grid>
                                <Grid item xs={12} textAlign={"left"} pt={0} onClick={() => {
                                    navigate('about-us/why-book-with-us')
                                }}>
                                    <LBVLabel style={{
                                        fontWeight: 100,
                                        cursor: 'pointer'
                                    }}>
                                        Why Book with Us
                                    </LBVLabel>
                                </Grid>
                                <Grid item xs={12} textAlign={"left"} pt={0} onClick={() => {
                                    navigate('about-us/best-price-guarantee')
                                }}>
                                    <LBVLabel style={{
                                        fontWeight: 100,
                                        cursor: 'pointer'
                                    }}>
                                        Best Price Guarantee
                                    </LBVLabel>
                                </Grid>
                                <Grid item xs={12} textAlign={"left"} pt={0} onClick={() => {
                                    navigate('about-us/terms-condition')
                                }}>
                                    <LBVLabel style={{
                                        fontWeight: 100,
                                        cursor: 'pointer'
                                    }}>
                                        Terms and Conditions Apply
                                    </LBVLabel>
                                </Grid>
                                <Grid item xs={12} textAlign={"left"} pt={0} onClick={() => {
                                    navigate('about-us/faq')
                                }}>
                                    <LBVLabel style={{
                                        fontWeight: 100,
                                        cursor: 'pointer'
                                    }}>
                                        FAQ
                                    </LBVLabel>
                                </Grid>
                            </Grid>
                            <Grid item container xs={6}>
                                <Grid item xs={12} textAlign={"left"} pt={0} onClick={() => {
                                    navigate('about-us/legal-information')
                                }}>
                                    <LBVLabel style={{
                                        fontWeight: 100,
                                        cursor: 'pointer'
                                    }}>
                                        Legal Information
                                    </LBVLabel>
                                </Grid>
                                <Grid item xs={12} textAlign={"left"} pt={0} onClick={() => {
                                    navigate('about-us/compliance-standard')
                                }}>
                                    <LBVLabel style={{
                                        fontWeight: 100,
                                        cursor: 'pointer'
                                    }}>
                                        Compliance and Standards
                                    </LBVLabel>
                                </Grid>
                                <Grid item xs={12} textAlign={"left"} pt={0} onClick={() => {
                                    navigate('about-us/client-protection')
                                }}>
                                    <LBVLabel style={{
                                        fontWeight: 100,
                                        cursor: 'pointer'
                                    }}>
                                        Client Protection
                                    </LBVLabel>
                                </Grid>
                                <Grid item xs={12} textAlign={"left"} pt={0} onClick={() => {
                                    navigate('about-us/contact-us')
                                }}>
                                    <LBVLabel style={{
                                        fontWeight: 100,
                                        cursor: 'pointer'
                                    }}>
                                        Contact Us
                                    </LBVLabel>
                                </Grid>
                                <Grid item xs={12} textAlign={"left"} pt={0} onClick={() => {
                                    navigate('about-us/24-7-support')
                                }}>
                                    <LBVLabel style={{
                                        fontWeight: 100,
                                        cursor: 'pointer'
                                    }}>
                                        24/7 Support Team
                                    </LBVLabel>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid> */}
                    {/* <Grid item xs={12} textAlign={"left"} p={2} pt={0}>
                        <LBVTitleLabel style={{
                            fontWeight: 400,
                        }}>Payment Accepted</LBVTitleLabel>
                        <Grid>
                            <img src={bankPayment} width={200} height={'auto'} />
                        </Grid>
                    </Grid> */}
                    <LBVLabel style={{
                            fontSize: 11,
                            fontWeight: 100
                            // color: 'rgb(157, 166, 171)'
                        }}>Copyright © {new Date().getFullYear()} Local Bali Villas</LBVLabel>
                </div>
                :
                <div style={{
                    left: 0,
                    right: 0,
                    bottom: 0,
                    paddingTop: 20,
                    paddingBottom: 10,
                    backgroundColor: '#957c6433',
                    marginTop: 20,
                }}>
                    <Container>
                        <Grid container xs={12}>
                            <Grid item xs={12} lg={6} textAlign={"left"} p={2} pt={0}>
                                <LBVTitleLabel style={{
                                    fontWeight: 400,
                                }}>About Local Bali</LBVTitleLabel>
                                <Grid>
                                    <LBVLabel style={{
                                        fontSize: 11,
                                        fontWeight: 100
                                    }}>
                                        Founded in 2020 in Bali, Currently, Local Bali Villas is a continuously growing new start-up company. Local Bali Villas’ mission is to make people around the world easier to find likewise their second home with the best service and become the unforgettable moments during their stay-cation in Bali.
                                        Local Bali Villas has been working together with more than thousands of the best villas in Bali for more than 10 years of experience in tourism. As foreseen, the booking service dilemma is commonly caused by a lack of miscommunication between both guests and the villas’ owner therefore we commit to minimize it to meet the guests’ expectations.
                                    </LBVLabel>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} lg={2}></Grid>
                            <Grid item xs={12} lg={4}>
                                <LBVTitleLabel style={{
                                    fontWeight: 400,
                                }}>Payment Accepted</LBVTitleLabel>
                                <Grid>
                                    <img src={bankPayment} width={200} height={'auto'} />
                                </Grid>
                            </Grid>
                            <div style={{
                                height: 1, 
                                width: '100%',
                                backgroundColor: '#0000001A'
                            }}></div>
                        </Grid>
                        <Grid container xs={12}>
                            <Grid xs={3} p={2}>
                                <LBVTitleLabel>
                                    More Information
                                </LBVTitleLabel>
                            </Grid>
                            <Grid xs={3} p={2}>
                                <Grid item xs={12} textAlign={"left"} pt={0} onClick={() => {
                                    navigate('about-us/who-are-we')
                                }}>
                                    <LBVLabel style={{
                                        fontWeight: 100,
                                        cursor: 'pointer'
                                    }}>
                                        Who Are We
                                    </LBVLabel>
                                </Grid>
                                <Grid item xs={12} textAlign={"left"} pt={0} onClick={() => {
                                    navigate('about-us/why-book-with-us')
                                }}>
                                    <LBVLabel style={{
                                        fontWeight: 100,
                                        cursor: 'pointer'
                                    }}>
                                        Why Book with Us
                                    </LBVLabel>
                                </Grid>
                                <Grid item xs={12} textAlign={"left"} pt={0} onClick={() => {
                                    navigate('about-us/best-price-guarantee')
                                }}>
                                    <LBVLabel style={{
                                        fontWeight: 100,
                                        cursor: 'pointer'
                                    }}>
                                        Best Price Guarantee
                                    </LBVLabel>
                                </Grid>
                                <Grid item xs={12} textAlign={"left"} pt={0} onClick={() => {
                                    navigate('about-us/terms-condition')
                                }}>
                                    <LBVLabel style={{
                                        fontWeight: 100,
                                        cursor: 'pointer'
                                    }}>
                                        Terms and Conditions Apply
                                    </LBVLabel>
                                </Grid>
                                <Grid item xs={12} textAlign={"left"} pt={0} onClick={() => {
                                    navigate('about-us/faq')
                                }}>
                                    <LBVLabel style={{
                                        fontWeight: 100,
                                        cursor: 'pointer'
                                    }}>
                                        FAQ
                                    </LBVLabel>
                                </Grid>
                                <Grid item xs={12} textAlign={"left"} pt={0} onClick={() => {
                                    navigate('about-us/privacy-policy')
                                }}>
                                    <LBVLabel style={{
                                        fontWeight: 100,
                                        cursor: 'pointer'
                                    }}>
                                        Privacy Policy
                                    </LBVLabel>
                                </Grid>
                            </Grid>
                            <Grid xs={3} p={2}>
                                <Grid item xs={12} textAlign={"left"} pt={0} onClick={() => {
                                    navigate('about-us/legal-information')
                                }}>
                                    <LBVLabel style={{
                                        fontWeight: 100,
                                        cursor: 'pointer'
                                    }}>
                                        Legal Information
                                    </LBVLabel>
                                </Grid>
                                <Grid item xs={12} textAlign={"left"} pt={0} onClick={() => {
                                    navigate('about-us/compliance-standard')
                                }}>
                                    <LBVLabel style={{
                                        fontWeight: 100,
                                        cursor: 'pointer'
                                    }}>
                                        Compliance and Standards
                                    </LBVLabel>
                                </Grid>
                                <Grid item xs={12} textAlign={"left"} pt={0} onClick={() => {
                                    navigate('about-us/client-protection')
                                }}>
                                    <LBVLabel style={{
                                        fontWeight: 100,
                                        cursor: 'pointer'
                                    }}>
                                        Client Protection
                                    </LBVLabel>
                                </Grid>
                                <Grid item xs={12} textAlign={"left"} pt={0} onClick={() => {
                                    navigate('about-us/contact-us')
                                }}>
                                    <LBVLabel style={{
                                        fontWeight: 100,
                                        cursor: 'pointer'
                                    }}>
                                        Contact Us
                                    </LBVLabel>
                                </Grid>
                                <Grid item xs={12} textAlign={"left"} pt={0} onClick={() => {
                                    navigate('about-us/24-7-support')
                                }}>
                                    <LBVLabel style={{
                                        fontWeight: 100,
                                        cursor: 'pointer'
                                    }}>
                                        24/7 Support Team
                                    </LBVLabel>
                                </Grid>
                            </Grid>
                            <Grid xs={3} p={2}>
                                <Grid xs={12}>
                                    <Grid xs={12}>
                                        <LBVTitleLabel>Follow Us</LBVTitleLabel>
                                    </Grid>
                                    <Grid xs={12}>
                                        <FaInstagram style={{
                                            color: '#957c64',
                                            marginRight: 3,
                                            marginTop: -2,
                                            cursor: 'pointer',
                                            minWidth: 20
                                        }} onClick={() => {
                                            window.open('https://www.instagram.com/localbalivillas/', '_blank')
                                        }}/>
                                        <FaFacebook style={{
                                            color: '#957c64',
                                            marginRight: 3,
                                            marginTop: -2,
                                            cursor: 'pointer',
                                            minWidth: 20
                                        }} onClick={() => {
                                            window.open('https://www.facebook.com/localbalivillas', '_blank')
                                        }}/>
                                        <FaTiktok style={{
                                            color: '#957c64',
                                            marginRight: 3,
                                            marginTop: -2,
                                            cursor: 'pointer',
                                            minWidth: 20
                                        }} onClick={() => {
                                            window.open('https://www.tiktok.com/@localbalivillas', '_blank')
                                        }}/>
                                    </Grid>
                                </Grid>
                                <Grid xs={12} pt={2}>
                                    <img src={lbvLogo} width={100} height={100} />
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <Grid container xs={12} lg={6} spacing={1}>
                            <Grid item xs={12}>
                                <LBVTitleLabel style={{
                                    fontWeight: 400,
                                }}>
                                    Contact Us
                                </LBVTitleLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <div style={{ display: 'flex' }} onClick={() => {
                                    window.open("https://maps.google.com/?q=-8.666457170367105,115.22611985571564", "_blank")
                                }}>
                                    <FaLocationDot style={{
                                        color: '#957c64',
                                        marginRight: 3,
                                        marginTop: 3,
                                        cursor: 'pointer',
                                        minWidth: 20
                                    }}/>
                                    <LBVLabel style={{
                                        fontWeight: 100,
                                        cursor: 'pointer'
                                    }}>
                                        Jl. Cok Agung Tresna - Renon, Komplek Pertokoan Griya Alamanda No. 12 A lt1 Denpasar - Bali
                                    </LBVLabel>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div onClick={() => {
                                    window.location.href = "tel:+6282340243600"
                                }}>
                                    <FaPhone style={{
                                        color: '#957c64',
                                        marginRight: 3,
                                        marginTop: -2,
                                        cursor: 'pointer',
                                        minWidth: 20
                                    }} onClick={() => {
                                        
                                    }}/>
                                    <LBVLabel style={{
                                        fontWeight: 100,
                                        cursor: 'pointer'
                                    }}>
                                        +62 823-4024-3600
                                    </LBVLabel>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div onClick={() => {
                                    window.open("https://api.whatsapp.com/send?phone=6282340243600", "_blank")
                                }}>
                                    <FaWhatsapp fontSize={20} style={{
                                        color: '#957c64',
                                        marginRight: 3,
                                        marginTop: -2,
                                        cursor: 'pointer',
                                        minWidth: 20
                                    }}/>
                                    <LBVLabel style={{
                                        fontWeight: 100,
                                        cursor: 'pointer'
                                    }}>
                                        +62 823-4024-3600
                                    </LBVLabel>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div onClick={() => {
                                    window.open('mailto:info@localbalivillas.com?subject=Subject&body=', "_blank")
                                }}>
                                    <FaEnvelope style={{
                                        color: '#957c64',
                                        marginRight: 3,
                                        marginTop: -2,
                                        cursor: 'pointer',
                                        minWidth: 20
                                    }}/>
                                    <LBVLabel style={{
                                        fontWeight: 100,
                                        cursor: 'pointer'
                                    }}>
                                        info@localbalivillas.com
                                    </LBVLabel>
                                </div>
                            </Grid>
                        </Grid> */}
                        {/* <Grid item xs={12} mt={1}>
                            <LBVTitleLabel style={{
                                fontWeight: 400,
                            }}>
                                    Connect with Us
                            </LBVTitleLabel>
                            <Grid xs={12}>
                                <FaInstagram style={{
                                    color: '#957c64',
                                    marginRight: 3,
                                    marginTop: -2,
                                    cursor: 'pointer',
                                    minWidth: 20
                                }} onClick={() => {
                                    window.open('https://www.instagram.com/localbalivillas/', '_blank')
                                }}/>
                                <FaFacebook style={{
                                    color: '#957c64',
                                    marginRight: 3,
                                    marginTop: -2,
                                    cursor: 'pointer',
                                    minWidth: 20
                                }} onClick={() => {
                                    window.open('https://www.facebook.com/localbalivillas', '_blank')
                                }}/>
                                <FaTiktok style={{
                                    color: '#957c64',
                                    marginRight: 3,
                                    marginTop: -2,
                                    cursor: 'pointer',
                                    minWidth: 20
                                }} onClick={() => {
                                    window.open('https://www.tiktok.com/@localbalivillas', '_blank')
                                }}/>
                            </Grid>
                        </Grid> */}
                        {/* <Grid item container xs={12}>
                            <Grid item xs={8} textAlign={"left"} mt={2}>
                                <LBVTitleLabel style={{
                                    fontWeight: 400,
                                }}>
                                    About Us
                                </LBVTitleLabel>
                                <Grid item container xs={12}>
                                    <Grid item container xs={4}>
                                        <Grid item xs={12} textAlign={"left"} pt={0} onClick={() => {
                                            navigate('about-us/who-are-we')
                                        }}>
                                            <LBVLabel style={{
                                                fontWeight: 100,
                                                cursor: 'pointer'
                                            }}>
                                                Who Are We
                                            </LBVLabel>
                                        </Grid>
                                        <Grid item xs={12} textAlign={"left"} pt={0} onClick={() => {
                                            navigate('about-us/why-book-with-us')
                                        }}>
                                            <LBVLabel style={{
                                                fontWeight: 100,
                                                cursor: 'pointer'
                                            }}>
                                                Why Book with Us
                                            </LBVLabel>
                                        </Grid>
                                        <Grid item xs={12} textAlign={"left"} pt={0} onClick={() => {
                                            navigate('about-us/best-price-guarantee')
                                        }}>
                                            <LBVLabel style={{
                                                fontWeight: 100,
                                                cursor: 'pointer'
                                            }}>
                                                Best Price Guarantee
                                            </LBVLabel>
                                        </Grid>
                                        <Grid item xs={12} textAlign={"left"} pt={0} onClick={() => {
                                            navigate('about-us/terms-condition')
                                        }}>
                                            <LBVLabel style={{
                                                fontWeight: 100,
                                                cursor: 'pointer'
                                            }}>
                                                Terms and Conditions Apply
                                            </LBVLabel>
                                        </Grid>
                                        <Grid item xs={12} textAlign={"left"} pt={0} onClick={() => {
                                            navigate('about-us/faq')
                                        }}>
                                            <LBVLabel style={{
                                                fontWeight: 100,
                                                cursor: 'pointer'
                                            }}>
                                                FAQ
                                            </LBVLabel>
                                        </Grid>
                                    </Grid>
                                    <Grid item container xs={4}>
                                        <Grid item xs={12} textAlign={"left"} pt={0} onClick={() => {
                                            navigate('about-us/legal-information')
                                        }}>
                                            <LBVLabel style={{
                                                fontWeight: 100,
                                                cursor: 'pointer'
                                            }}>
                                                Legal Information
                                            </LBVLabel>
                                        </Grid>
                                        <Grid item xs={12} textAlign={"left"} pt={0} onClick={() => {
                                            navigate('about-us/compliance-standard')
                                        }}>
                                            <LBVLabel style={{
                                                fontWeight: 100,
                                                cursor: 'pointer'
                                            }}>
                                                Compliance and Standards
                                            </LBVLabel>
                                        </Grid>
                                        <Grid item xs={12} textAlign={"left"} pt={0} onClick={() => {
                                            navigate('about-us/client-protection')
                                        }}>
                                            <LBVLabel style={{
                                                fontWeight: 100,
                                                cursor: 'pointer'
                                            }}>
                                                Client Protection
                                            </LBVLabel>
                                        </Grid>
                                        <Grid item xs={12} textAlign={"left"} pt={0} onClick={() => {
                                            navigate('about-us/contact-us')
                                        }}>
                                            <LBVLabel style={{
                                                fontWeight: 100,
                                                cursor: 'pointer'
                                            }}>
                                                Contact Us
                                            </LBVLabel>
                                        </Grid>
                                        <Grid item xs={12} textAlign={"left"} pt={0} onClick={() => {
                                            navigate('about-us/24-7-support')
                                        }}>
                                            <LBVLabel style={{
                                                fontWeight: 100,
                                                cursor: 'pointer'
                                            }}>
                                                24/7 Support Team
                                            </LBVLabel>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={4} textAlign={"left"} p={2} pt={0}>
                                <LBVTitleLabel style={{
                                    fontWeight: 400,
                                }}>Payment Accepted</LBVTitleLabel>
                                <Grid>
                                    <img src={bankPayment} width={200} height={'auto'} />
                                </Grid>
                            </Grid>
                        </Grid> */}
                    </Container>
                    <Grid textAlign={"center"} mt={3} pb={1}>
                        <LBVLabel style={{
                            fontSize: 11,
                            fontWeight: 100
                            // color: 'rgb(157, 166, 171)'
                        }}>Copyright © {new Date().getFullYear()} Local Bali Villas</LBVLabel>
                    </Grid>
                </div>
            }
        </>
    )
}