import React from "react"
import { Card } from "shards-react"
import { LBVLabel } from "../label/LBVLabel"
import { Grid } from "@mui/material"
import { currencyFormat } from "../../helper/numberFormat"
import { color } from "../../helper/constant"

function PropertyCard (
    props: {
        value: any,
        onSelect: () => void,
        selected: boolean
    }
) {

    const { value, onSelect, selected } = props

    return (
        <Card onClick={() => {
            onSelect()
        }} style={ selected ? {
            border: '2px solid #957c64B2',
            minWidth: 100,
            minHeight: 80,
        } : {
            minWidth: 100,
            minHeight: 80,
        }}>
            <img style={{
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                height: window.innerWidth >= 444 ? 160 : 120
            }} src={value.propertyImage[0]} width={'100%'} height={'100%'}/>
            <Grid item xs sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                background: '#957c64',
                borderBottomLeftRadius: 8,
                borderTopRightRadius: 8,
                color: 'white',
                paddingLeft: 1,
                paddingRight: 1
            }} pr={2} pl={2}>
                <LBVLabel inheritColor={true} subtitle={true}>{value.location ? `${value.location[0].toUpperCase()}${value.location.slice(1)}` : ''}</LBVLabel>
            </Grid>
            <Grid container p={1} minHeight={45}>
                <Grid item xs={12} mb={-1}>
                    <LBVLabel style={{
                        fontSize: 14
                    }}>{value.name}</LBVLabel>
                </Grid>
                {/* <Grid item xs>
                    <LBVLabel subtitle={true}>{value.location ? `${value.location[0].toUpperCase()}${value.location.slice(1)}` : ''}</LBVLabel>
                </Grid> */}
                {
                    value.price &&
                    <Grid item xs={12} mb={-1} textAlign={"right"} justifyItems={"flex-end"}>
                        <Grid mb={-1}>
                            <LBVLabel style={{
                                fontSize: 10,
                                color: color.primary
                            }}>Start From</LBVLabel>
                        </Grid>
                        <LBVLabel subtitle={false}>{value.price ? currencyFormat(value.price) : ''}</LBVLabel>
                    </Grid>
                }
            </Grid>
        </Card>
    )
}

export default PropertyCard