import { client, errorValidation } from "./service";

export const getProperty = async function (id) {
  try {
    let response = await client.get(`/property/${id}`);
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data, error: null };
  } catch (e) {
    // return { error: e.response.data.errorCode }
    return { data: null, ...errorValidation(e) }
  }
}


export const getProperties = async function ({
  location,
  adult,
  child,
  rooms,
  limit = null
}) {
  try {
      let response = await client.get(`/properties/list?location=${location || ''}&adult=${adult || ''}&child=${child || ''}&rooms=${rooms || ''}&limit=${limit || ''}`);
      if (response.data.statusCode !== 200) { throw response.data.data; }
      let data = response.data.data;
      return { data, error: null };
    } catch (e) {
      // return { error: e.response.data.errorCode }
      return { data: null, ...errorValidation(e) }
    }
}