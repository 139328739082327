import React, { useEffect, useState } from "react"
import LBVNav from "../../component/Navbar"
import { Grid } from "@mui/material"
import { LBVLabel, LBVTitleLabel } from "../../component/label/LBVLabel"
import { color } from "../../helper/constant"


function WhyBookWithUs ({
    
}) {
    return (
        <Grid>
            <LBVNav />
            <LBVTitleLabel>Why Book With Us</LBVTitleLabel>
            <Grid>
                <LBVLabel>
                    <span style={{fontWeight: 'bold', color: color.primary}}>1. Exceptional Service:</span> 
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    We pride ourselves on providing top-notch customer service, ensuring your needs are met with care and efficiency.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    <span style={{fontWeight: 'bold', color: color.primary}}>2. Quality Accommodations:</span>
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    Our properties are meticulously selected to offer comfort, luxury, and a memorable stay.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    <span style={{fontWeight: 'bold', color: color.primary}}>3. Competitive Pricing:</span>
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    We strive to offer the best rates available, ensuring value for your money.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    <span style={{fontWeight: 'bold', color: color.primary}}>4. Convenience:</span>
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    From booking to check-out, we aim to make your experience seamless and hassle-free.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    <span style={{fontWeight: 'bold', color: color.primary}}>5. Local Expertise:</span>
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    Benefit from our deep knowledge of the area, providing insider tips and recommendations.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    <span style={{fontWeight: 'bold', color: color.primary}}>6. Flexibility:</span>
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    We understand travel plans can change, and we offer flexible booking options to accommodate your needs (Terms and conditions apply in accordance with the policies of each accommodation, tour operator, and attraction).
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    <span style={{fontWeight: 'bold', color: color.primary}}>7. Safety and Security:</span> 
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    Your safety is our priority, and we maintain high standards to ensure a secure environment for all guests.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    <span style={{fontWeight: 'bold', color: color.primary}}>8. Direct Communication:</span>
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    Enjoy direct and transparent communication with us, ensuring clarity and peace of mind throughout your booking process.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    <span style={{fontWeight: 'bold', color: color.primary}}>9. Additional Services:</span>
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    We offer a range of additional services such as airport transfers, local tours, and more, to enhance your stay.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    <span style={{fontWeight: 'bold', color: color.primary}}>10. Customer Satisfaction:</span>
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    Ultimately, we aim for your complete satisfaction, striving to exceed expectations and create lasting memories.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    Experience the difference when you choose to book with us.
                </LBVLabel>
            </Grid>
        </Grid>
    )
}

export default WhyBookWithUs