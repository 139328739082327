import { Grid } from "@mui/material"
import React, { useEffect, useState } from "react"
import LBVNav from "../../component/Navbar"
import { LBVLabel, LBVTitleLabel } from "../../component/label/LBVLabel"
import { color } from "../../helper/constant"

function ComplianceAndStandard ({
    
}) {
    return (
        <Grid>
            <LBVNav />
            <LBVTitleLabel>Compliance and Standards</LBVTitleLabel>
            <Grid>
                <LBVLabel>
                    <span style={{fontWeight: 'bold', color: color.primary}}>Adherence to Local Laws:</span>
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    We strictly adhere to all local laws and regulations governing the travel and tourism industry in Indonesia. This includes compliance with health and safety standards, environmental regulations, and consumer protection laws.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    <span style={{fontWeight: 'bold', color: color.primary}}>Ethical Practices:</span>
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    We are committed to conducting our business ethically and responsibly. We work with reputable partners and service providers who share our commitment to quality and integrity.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    <span style={{fontWeight: 'bold', color: color.primary}}>Sustainable Tourism:</span>
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    As part of our commitment to sustainable tourism, we strive to minimize our environmental impact and support local communities. We promote eco-friendly practices and encourage our clients to respect Bali’s natural and cultural heritage
                </LBVLabel>
            </Grid>
        </Grid>
    )
}

export default ComplianceAndStandard