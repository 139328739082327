import { Grid } from "@mui/material"
import React, { useEffect, useState } from "react"
import LBVNav from "../../component/Navbar"
import { LBVLabel, LBVTitleLabel } from "../../component/label/LBVLabel"
import { color } from "../../helper/constant"


function BestPriceGuarantee ({
    
}) {
    return (
        <Grid>
            <LBVNav />
            <LBVTitleLabel>Best Price Guarantee</LBVTitleLabel>
            <Grid>
                <LBVLabel>
                    At Local Bali, we are committed to offering you the best price for your accommodation bookings. Our Best Price Guarantee ensures that you can book with confidence, knowing you're getting the most competitive rates available. Here’s what our Best Price Guarantee entails:
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    <span style={{fontWeight: 'bold', color: color.primary}}>1. Lowest Rates:</span>
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    We guarantee that the rates offered on our website are the lowest available publicly. If you find a lower rate for the same booking elsewhere, let us know, and we will match it.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    <span style={{fontWeight: 'bold', color: color.primary}}>2. Simple Process:</span>
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    Submitting a claim is easy. Just provide us with details of the lower rate you found, including a screenshot or a link to the website where the lower rate is advertised.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    <span style={{fontWeight: 'bold', color: color.primary}}>3. Terms and Conditions:</span>
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    Our Best Price Guarantee applies to identical accommodations, dates of stay, room types, and booking conditions (including cancellation policy, payment terms, etc.).
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    <span style={{fontWeight: 'bold', color: color.primary}}>4. Prompt Response:</span>
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    We review each claim promptly and aim to respond within 24 hours to confirm the lower rate and adjust your booking accordingly.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    <span style={{fontWeight: 'bold', color: color.primary}}>5. Exceptional Service:</span>
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    Beyond offering the best rates, we are committed to providing exceptional customer service throughout your booking experience and stay.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    Booking with Local Bali not only competitive pricing but also peace of mind knowing that you’ve secured the best deal available. Discover more about our Best Price Guarantee and start planning your next stay with confidence.
                </LBVLabel>
            </Grid>
        </Grid>
    )
}

export default BestPriceGuarantee