import React, { useEffect, useState } from "react";

import { DateRangePicker } from 'react-date-range';
import { format } from "date-fns";

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { convertPrice } from "../../../helper/priceFunction";
import moment from "moment";

function LBVCalendar ({
    onChange, vertical = false, price, disabledDates, month = 2
}) {

    const [ orientation, setOrientation ] = useState("horizontal")

    const [calendarState, setCalendarState] = useState([{
        startDate: new Date(),
        endDate: new Date(moment(new Date()).add(1, 'day')),
        key: 'selection'
    }]);

    const [disableDateList, setDisableDateList] = useState([])

    useEffect(() => {
        if (vertical) { setOrientation("vertical") }
    }, [vertical])

    // useEffect(() => {
    //     if (disabledDates) {
    //         let counts = {}
    //         let { roomType } = disabledDates
    //         let newDisabledDates = []
    //         let disableDateList = []
    //         for (let i = 0; i < roomType.length; i ++) {
    //             let { disabledDate } = roomType[i];
    //             newDisabledDates = newDisabledDates.concat(disabledDate)
    //         }
    //         newDisabledDates.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });
    //         console.log('here information', counts)
    //         let objKeys = Object.keys(counts)
    //         for (let i = 0; i < objKeys.length; i ++) {
    //             if (counts[objKeys[i]] === roomType.length) {
    //                 disableDateList.push(objKeys[i])
    //             }
    //         }
    //         setDisableDateList(disableDateList)
    //         // if (isDisabled == roomType.length) { 
    //         //     let currDisable = disableDateList
    //         //     currDisable.push(currDay)
    //         //     setDisableDateList(currDisable)
    //         // }
    //     }
    // }, [disabledDates])

    useEffect(() => {
        if (moment(calendarState[0].startDate).format('YYYY-MM-DD') === moment(calendarState[0].endDate).format('YYYY-MM-DD')) { return; }
        onChange(calendarState)
    }, [calendarState])

    const onChangeCalendarSelection = (e) => {
        setCalendarState([e.selection])
    }

    function customDayContent(day) {
        let priceInfo = null;
        let availbility = null;
        if (price) {
            let priceValue = 0
            let lowestPriceValue = 0
            for (let i = 0; i < price.length; i ++) {
                // priceValue = price[i].price
                // if (!price[i].date && !price[i].day) {
                //     priceValue = price[i].price
                // }
                if (price[i].day && price[i].day.indexOf(format(day, "eeee")) >= 0) {
                    priceValue = price[i].price
                }
                if (price[i].date && price[i].date.indexOf(format(day, "yyyy-MM-dd")) >= 0) {
                    priceValue = price[i].price
                }
                if ((lowestPriceValue > 0) && (lowestPriceValue > priceValue) || lowestPriceValue == 0) {
                    lowestPriceValue = priceValue
                } else {
                    priceValue = lowestPriceValue
                }
            }
            if (priceValue == 0 && price.length > 0) { 
                priceValue = price[0].price
            }
            if (priceValue > 0) {
                priceInfo = (
                    <div
                        className="rdrPriceInfo"
                        style={{
                            height: '10px',
                            // width: "5px",
                            borderRadius: "100%",
                            // background: "orange",
                            position: "absolute",
                            top: 14,
                            right: 0,
                            left: 0,
                            fontSize: 10,
                            textAlign: 'center',
                            color: '#a2a2a2'
                        }}
                    >{convertPrice(priceValue)}</div>
                )
            }
        }
        if (disabledDates) {
            let { roomType } = disabledDates
            let currDay = moment(day).format('YYYY-MM-DD')
            let isDisabled = 0
            for (let i = 0; i < roomType.length; i ++) {
                let { disabledDate } = roomType[i];
                if (disabledDate.indexOf(currDay) >= 0) {
                    isDisabled += 1;
                }
            }
            if (isDisabled == roomType.length) { 
                availbility =  <div
                    className="rdrPriceInfo"
                    style={{
                        height: '10px',
                        borderRadius: "100%",
                        position: "absolute",
                        top: 14,
                        right: 0,
                        left: 0,
                        fontSize: 10,
                        textAlign: 'center',
                        color: '#a2a2a2'
                    }}
                >Sold</div>
            }
        }
        return (
          <div style={ (availbility || priceInfo) ? {} : { marginTop: '4px' }}>
            <span style={availbility ? {textDecoration: 'line-through', color: '#a2a2a2'} : {}}>{format(day, "d")}</span>
            {availbility ? availbility : priceInfo}
          </div>
        )
    }

    return (
        <DateRangePicker
            minDate={new Date()}
            maxDate={new Date(moment().add('6', 'months'))}
            months={month}
            showPreview={false}
            showSelectionPreview={false}
            ranges={calendarState}
            onChange={onChangeCalendarSelection}
            direction={orientation}
            dayContentRenderer={customDayContent}
            disabledDates={disableDateList}
        />
    )
}

export default LBVCalendar