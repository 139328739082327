import React, { useEffect, useState } from "react"
import { Avatar, Grid, Button } from "@mui/material"
import { Card } from "shards-react"
import { Rating } from 'react-simple-star-rating'
import { LBVInput } from "../input/LBVInput"
import { LBVLabel } from "../label/LBVLabel"
import { toast } from "react-toastify"
import { getReviews, submitReview, updateReview } from "../../services/reviewService"
import ReactCountryFlag from "react-country-flag"

function ReviewComponent ({
    propertyId
}) {

    const [ reviews, setReviews ] = useState([])

    const [ reviewForm, setReviewForm ] = useState({
        rating: 5,
        email: "",
        review: ""
    })
    const [ joi, setJoi ] = useState({
        email: null
    })
    const [ rating, setRating ] = useState(0)

    useEffect(() => {
        loadReview()
    }, [])

    useEffect(() => {
        let ratingCount = 0
        for (let i = 0; i < reviews.length; i ++) {
            ratingCount += reviews[i].rating
        }
        setRating(Math.ceil(ratingCount / reviews.length))
    }, [reviews])

    useEffect(() => {
        setJoi({
            email: reviewForm.email ? validateEmail(reviewForm.email) ? false : true : true,
        })
    }, [reviewForm])

    const onChange = (e) => {
        setReviewForm(prev => ({
            ...prev,
            ...e
        }))
    }

    const loadReview = async () => {
        try {
            let { data, error } = await getReviews({
                propertyId
            })
            if (data) { setReviews(data) }
            console.log(error)
        } catch (error) {
            console.log(error)
        }
    }

    const sendReview = async () => {
        try {

            if (reviewForm._id) {
                let { data, error } = await updateReview({
                    _id: reviewForm._id,
                    email: reviewForm.email,
                    review: reviewForm.review,
                    rating: reviewForm.rating,
                    propertyId,
                })
                if (error) { 
                    if (data) { throw { error, data} }
                    throw error 
                }
            } else {
                let { data, error } = await submitReview({
                    email: reviewForm.email,
                    review: reviewForm.review,
                    rating: reviewForm.rating,
                    propertyId,
                })
                if (error) { 
                    if (data) { throw { error, data} }
                    throw error 
                }
            }
            setReviewForm({
                rating: 0,
                email: "",
                review: ""
            })
            loadReview()
            toast( 'Thank you for your review', {
                toastId: "comment-error",
                position: "bottom-center",
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            })
        } catch (error) {
            console.log('dataResponse', error)
            if (error.data) { 
                setReviewForm(prev => ({
                    ...prev,
                    _id: error.data._id
                }))
            }
            toast( error.error ? error.error : error, {
                toastId: "comment-error",
                position: "bottom-center",
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            })
        }
    }

    const validateEmail = (mail) => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
           return (true)
        }
        return (false)
    }

    return (
        <Grid xs={12}>
            <hr />
            <Grid container xs={12} pb={2}>
                <Grid item container xs={12} lg={6}>
                    <Grid item xs={12} lg={12} mt={1} pl={2} pr={2}>
                        <Rating initialValue={reviewForm.rating} onClick={(e) => {
                            onChange({rating: e})
                        }} />
                    </Grid>
                    <Grid item xs={12} lg={8} mt={1} pl={2} pr={2}>
                        <LBVInput placeHolder="Email" value={reviewForm.email} onChange={(e) => {
                            onChange({email: e.currentTarget.value})
                        }}/>
                    </Grid>
                    <Grid item xs={12} lg={12} >
                        <LBVInput type={"textarea"} value={reviewForm.review} rows={4} 
                            onChange={(e) => {
                                onChange({review: e.currentTarget.value})
                            }}
                        placeHolder="Write down your review ..."/>
                    </Grid>
                </Grid>
                <Grid item container xs={12} lg={12}>
                    <Grid item xs={12} lg={6} textAlign={"right"}>
                        <Button variant="contained" disabled={joi.email} onClick={() => {
                            console.log(reviewForm)
                            if (reviewForm.email && reviewForm.rating) {
                                sendReview()
                                return;
                            }
                            toast( !reviewForm.email ? "Email can't be empty" : 'Coming Soon', {
                                toastId: "comment-error",
                                position: "bottom-center",
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "dark",
                            })
                        }}>{reviewForm._id ? 'Update' : 'Submit'} Review</Button>
                    </Grid>
                </Grid>
            </Grid>
            {/* <hr/> */}
            <Grid container mt={2} spacing={0.5} textAlign={"center"} alignContent={"center"}>
                <Grid item container>
                    <Grid item mt={.5}>
                        {rating || 0}
                    </Grid>
                    <Grid item ml={.5}>
                        <Rating readonly={true} initialValue={rating} size={20}/>
                    </Grid>
                </Grid>
                {/* <div style={{ width: 1, height: '100%', backgroundColor: 'rgba(0,0,0,.1)'}}/> */}
                <Grid item>
                    {reviews.length} Reviews
                </Grid>
            </Grid>
            <Grid container mt={1} xs={12}>
                {
                    reviews.map( value => {
                        return (
                            <Grid item xs={12} lg={4}>
                                <Card>
                                    <Grid container alignItems={"center"}>
                                        <Grid item container p={2} xs={4} justifyContent={"left"}
                                            
                                        >
                                            <Grid><Avatar sx={{mr: 0.5, ml: 0.5, width: 25, height: 25}}/></Grid>
                                            <Grid lineHeight={1} sx={{mt: 0.5}}>
                                                <div><LBVLabel>{value.user.name}</LBVLabel></div>
                                                <div><ReactCountryFlag countryCode={value.user.country.iso2} style={{
                                                    marginRight: 4
                                                }} /><LBVLabel subtitle>{value.user.country.name}</LBVLabel></div>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={7} p={1} sx={{
                                            minHeight: 100
                                        }} textAlign={"left"}>
                                            <Grid p={0} m={0} mb={1} xs={12} textAlign={"left"}><Rating readonly={true} initialValue={value.rating} size={15}/></Grid>
                                            {value.review && <div>"{value.review}"</div>}
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </Grid>
    )
}

export default ReviewComponent