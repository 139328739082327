import React, { useEffect, useState } from "react"
import LBVNav from "../../component/Navbar"
import { Grid } from "@mui/material"
import { getProperties } from "../../services/propertiesService"
import { isPhone } from "../../helper/constant"
import PropertyCard from "../../component/card/PropertyCard"
import { useNavigate } from "react-router-dom"
import Region from "../../component/booking/form/Region"
import BookingMain from "../../component/booking/Main"

function PlaceList ({
    
}) {

    const [ propertiesList, setPropertiesList ] = useState([])
    const [ form, setForm ] = useState(null)
    const navigate = useNavigate()

    // useEffect(() => {
    //     loadProperties()
    // }, [])

    useEffect(() => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        if (params.size <= 0) { loadProperties(); return; }
        let location = params.get('location')
        let dateStart = params.get('dateStart')
        let dateEnd = params.get('dateEnd')
        let adult = params.get('adult')
        let children = params.get('children')
        let rooms = params.get('rooms')
        let days = params.get('days')
        setForm({
            location: location,
            guests: {
                total: Number(adult || 0) + Number(children || 0),
                adult: Number(adult || 0),
                children: Number(children || 0),
                rooms: Number(rooms || 0)
            },
            dateInfo: (!dateStart || !dateEnd) ? null : {
                startDate: dateStart ? new Date(dateStart) : null,
                endDate: dateEnd ? new Date(dateEnd) : null,
                days: days ? days.split(',') : null,
                totalDays: days ? days.split(',').length : 0
            }
        })
        console.log(location, dateStart, dateEnd, adult, children, rooms, days)
        loadProperties(location, adult, children, rooms)
    }, [])
    
    const loadProperties = async (location, adult, children, rooms) => {
        let { data, error } = await getProperties({
            location: location || '',
            adult,
            child: children,
            rooms
        })
        console.log(data)
        if (error) { console.log(error) }
        if (data) { setPropertiesList(data) }
    }

    return (
        <Grid container>
            <Grid container xs={12} md={12} justifyContent={"left"} alignItems={'top'}>
                <LBVNav />
            </Grid>
            <Grid xs={12} md={12} lg={12}>
                <BookingMain 
                    filter={form}
                    info={form ? form.location : ''}
                    properties={true}
                    dates={form ? form.dateInfo : null}
                />
            </Grid>
            <Grid container mt={2}>
                {
                    propertiesList.map(value => {
                        return(
                            <div style={{
                                padding: 8,
                                flexShrink: 0,
                                flexGrow: 0,
                                flexBasis: isPhone(1000) ? isPhone(770) ? '50%' : '30%' : '25%'
                            }}>
                                <PropertyCard value={value} onSelect={() => {
                                    navigate(`/${value["type"]}/${value["key"]}`)
                                }} selected={false}/>
                            </div>
                        )
                    })
                }
            </Grid>
        </Grid>
    )
}

export default PlaceList