import React, { useEffect } from 'react';

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import routes from './routes';
import Main from './pages/Main';
import 'react-calendar/dist/Calendar.css';

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    if (document.scrollingElement) {
      document.scrollingElement.scrollTop = 0;
    }
  }, [pathname]);

  const getRoutes = (allRoutes : any[]) : any =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route path={route.route} element={route.component} key={route.key} />;
      }

      return null;
  });

  return (
    <Routes>
      {getRoutes(routes)}
      <Route path="/" element={<Main />} />
      {/* <Route path="/about-us" element={<AboutUs />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/products" element={<Product />} />
      <Route path="/gallery" element={<Gallery />} />
      <Route path="/presentation" element={<Presentation />} /> */}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default App;
