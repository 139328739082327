import React, { useState, useEffect } from "react"
import LBVNav from "../component/Navbar"
import BookingMain from "../component/booking/Main"
import { Dialog, Grid, ImageList, ImageListItem, InputAdornment } from "@mui/material"
import { Button, Container } from "shards-react"
import { LBVLabel, LBVTitleLabel } from "../component/label/LBVLabel"
import PropertyCard from "../component/card/PropertyCard"
import { isPhone } from "../helper/constant"
import { useWindowSize } from "../helper/windowResize"
import Footer from "../component/Footer"
import { useNavigate } from "react-router-dom"
import { getProperties } from "../services/propertiesService"
import LBVLoading from "../component/loading/LBVLoading"
import Slider from "react-slick";
import { CustomSlickNextArrow, CustomSlickPrevArrow } from "../component/slider/SlideButton"

import PercentIcon from '@mui/icons-material/Percent';
import BestPrice from "../assets/best-price.svg";
import NoHiddenCharges from "../assets/no-hidden-charges.svg";
import SecurePayment from "../assets/secure-payment.svg";
import TeleWorking from "../assets/teleworking.svg";
import { getCoupons } from "../services/couponService"
import { LBVInput } from "../component/input/LBVInput"
import { toast } from "react-toastify"

function Main () {

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: isPhone(1000) ? isPhone(770) ? 2 : 3 : 4,
        slidesToScroll: isPhone(1000) ? isPhone(770) ? 2 : 3 : 4,
        nextArrow: <CustomSlickNextArrow />,
        prevArrow: <CustomSlickPrevArrow />
    };

    const [ hotelList, setHotelList ] = useState([])
    const [ couponList, setCouponList ] = useState([])
    const [ couponDetail, setCouponDetail ] = useState({
        open: false,
        coupon: null
    })


    const [ windowSize, setWindowSize ] = useState(useWindowSize())
    const navigate = useNavigate()

    useEffect(() => {
        loadProperties()
        loadCouponList()
    }, [])


    const loadProperties = async () => {
        let { data, error } = await getProperties({
            location: '',
            limit: 3
        })
        if (error) { console.log(error) }
        if (data) { 
            data = data.concat({
                type: "view_all"
            })
            setHotelList(data) 
        }
    }

    const loadCouponList = async () => {
        let { data, error } = await getCoupons()
        if (error) { console.log(error) }
        if (data) { 
            setCouponList(data) 
        }
    }

    const copyToClipboard = (data, message = 'Value has been copied') => {
        if (navigator && navigator.clipboard) {
          navigator.clipboard.writeText(data);
          toast(message, {
            toastId: "clipboard-true",
            position: "bottom-center",
            autoClose: 1000,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "dark",
        })
        }
    }

    return (
        <>
            <Grid container xs={12} md={12} justifyContent={"left"} alignItems={'top'}>
                <LBVNav />
            </Grid>
            <Grid xs={11} md={11} lg={11}>
                <BookingMain />
            </Grid>
            <Grid mt={4} xs={11} md={11} lg={11}>
                <LBVTitleLabel>Popular Destination</LBVTitleLabel>
                {/* <div style={{
                    display: 'flex',
                    overflowX: 'auto',
                    paddingBottom: 20,
                    paddingLeft: 10,
                    marginLeft: isPhone() ? -18 : 0
                }}>
                {
                    hotelList.map(value => {
                        return(
                            <div style={{
                                padding: 8,
                                flexShrink: 0,
                                flexGrow: 0,
                                flexBasis: isPhone(1000) ? isPhone(770) ? '50%' : '30%' : '25%'
                            }}>
                                <PropertyCard value={value} onSelect={() => {
                                    navigate(`/${value["type"]}/${value["key"]}`)
                                }} selected={false}/>
                            </div>
                        )
                    })
                }
                </div> */}
                {
                    (hotelList && hotelList.length > 0) &&
                    <Slider {...settings}>
                    {
                        hotelList.map(value => {
                            if (value.type && value.type == 'view_all') {
                                return (
                                    <Grid container xs={12} sx={{
                                        minHeight: 80,
                                        cursor: 'pointer'
                                    }} textAlign={"center"}
                                        margin={'auto'}
                                        mt={'45%'}
                                        onClick={() => {
                                            navigate('/properties')
                                        }}
                                    >
                                        View Other Property
                                    </Grid>
                                )
                            } else {
                                return(
                                    <Grid p={1} pb={5}>
                                        <PropertyCard value={value} onSelect={() => {
                                            navigate(`/${value["type"]}/${value["key"]}`)
                                        }} selected={false}/>
                                    </Grid>
                                )
                            }
                        })
                    }
                    </Slider>
                }
            </Grid>
            <Grid container mt={2}>
                <Grid container>
                    <div style={{
                        display: 'flex',
                        overflowX: 'auto',
                        paddingBottom: 20,
                        // paddingLeft: 12,
                        // marginLeft: isPhone() ? -18 : 0
                    }}>
                        {
                            couponList.map( value => {
                                return (
                                    <Grid container item margin={0.5} p={2} style={{
                                        border: 'solid 0.1px #957c64',
                                        borderLeft: 'solid 4px #957c64',
                                        borderRadius: 8,
                                        cursor: 'pointer',
                                        minHeight: 120,
                                        minWidth: 200
                                    }}>
                                        <LBVLabel style={{
                                            color: '#957c64',
                                        }}><PercentIcon/> {value.name}</LBVLabel>
                                        <LBVInput value={value.couponCode} styles={{
                                            marginTop: 1
                                        }} inputProps={{
                                            InputProps: {
                                                // inputProps: { min: moment(new Date()).format("YYYY-MM-DD"), max: checkAdvancedDays()}
                                                inputProps: { readOnly: true },
                                                endAdornment: <InputAdornment position="end">
                                                            <LBVLabel style={{
                                                            color: '#957c64',
                                                            cursor: 'pointer'
                                                        }} onClick={() => {
                                                            copyToClipboard(value.couponCode, "Coupon code has been copied")
                                                        }}>{"Copy"}</LBVLabel>
                                                    </InputAdornment>
                                            }
                                        }}/>
                                        <Grid item mt={2} style={{
                                            bottom: 0
                                        }} onClick={() => {
                                            setCouponDetail({
                                                open: true,
                                                coupon: value
                                            })
                                        }}>
                                            <LBVLabel style={{
                                                color: '#957c64',
                                                fontSize: 10
                                            }}>{"Terms and Condition"}</LBVLabel>
                                        </Grid>
                                    </Grid>
                                )
                            })
                        }
                    </div>
                </Grid>
            </Grid>
            <Grid mt={2}>
                <Grid xs={12} textAlign={"center"}>
                    <LBVTitleLabel style={{
                        fontSize: 24
                    }}>Why Book with us</LBVTitleLabel>
                </Grid>
                <Grid container xs={12} mt={2}>
                    <Grid item xs={12} sm={3} p={2} alignItems={"center"} textAlign={"center"}>
                        <Grid xs={12} alignItems={"center"}>
                            <img src={BestPrice} width={80} height={80}/>
                        </Grid>
                        <Grid xs={12} mt={1}>
                            <LBVLabel style={{
                                color: '#957c64'
                            }}>Best Rate Guarantee</LBVLabel>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={3} p={2} alignItems={"center"} textAlign={"center"}>
                        <Grid xs={12} alignItems={"center"}>
                            <img src={NoHiddenCharges} width={80} height={80}/>
                        </Grid>
                        <Grid xs={12} mt={1}>
                            <LBVLabel style={{
                                color: '#957c64'
                            }}>No Hidden Fees</LBVLabel>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={3} p={2} alignItems={"center"} textAlign={"center"}>
                        <Grid xs={12} alignItems={"center"}>
                            <img src={SecurePayment} width={80} height={80}/>
                        </Grid>
                        <Grid xs={12} mt={1}>
                            <LBVLabel style={{
                                color: '#957c64'
                            }}>Secure Payment Guarantee</LBVLabel>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={3} p={2} alignItems={"center"} textAlign={"center"}>
                        <Grid xs={12} alignItems={"center"}>
                            <img src={TeleWorking} width={80} height={80}/>
                        </Grid>
                        <Grid xs={12} mt={1}>
                            <LBVLabel style={{
                                color: '#957c64'
                            }}>Direct Support</LBVLabel>
                        </Grid>
                    </Grid>
                </Grid>
                {/* <Grid textAlign={"center"} mt={2}>
                    <LBVTitleLabel style={{
                        fontSize: 24
                    }}>About Us</LBVTitleLabel>
                </Grid>
                <Grid mt={1}>    
                    <LBVLabel>
                        Founded in 2020 in Bali, Currently, Local Bali Villas is a continuously growing new start-up company. Local Bali Villas’ mission is to make people around the world easier to find likewise their second home with the best service and become the unforgettable moments during their stay-cation in Bali.
                        Local Bali Villas has been working together with more than thousands of the best villas in Bali for more than 10 years of experience in tourism. As foreseen, the booking service dilemma is commonly caused by a lack of miscommunication between both guests and the villas’ owner therefore we commit to minimize it to meet the guests’ expectations.
                    </LBVLabel>
                </Grid> */}
            </Grid>
            <Dialog open={couponDetail.open} 
                fullWidth
                maxWidth="sm"
                onClose={() => {
                    setCouponDetail({ open: false, coupon: null })
                }}
            >
                <Grid container p={2}>
                    <Grid item xs={12}>
                        <LBVLabel style={{
                            color: '#957c64',
                        }}>{"Terms and Condition"}</LBVLabel>
                    </Grid>
                    {
                        couponDetail.coupon &&
                        <Grid item xs={12}>
                            <LBVLabel>
                                <div dangerouslySetInnerHTML={{__html: couponDetail.coupon.termsCondition}}/>
                            </LBVLabel>
                        </Grid>
                    }
                </Grid>
            </Dialog>
        </>
    )
}

export default Main