import { Grid } from "@mui/material"
import React, { useEffect, useState } from "react"
import LBVNav from "../../component/Navbar"
import { LBVLabel, LBVTitleLabel } from "../../component/label/LBVLabel"
import { color } from "../../helper/constant"

function TermsAndConditionApply ({
    
}) {
    return (
        <Grid>
            <LBVNav />
            <LBVTitleLabel>Terms and Conditions Apply</LBVTitleLabel>
            <Grid>
                <LBVLabel>
                    As per the policies of each accommodation, tour operator, and activity provider, the following terms and conditions apply:
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    <span style={{fontWeight: 'bold', color: color.primary}}>1. Booking Confirmation:</span>
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    All bookings are subject to availability and confirmation from the respective accommodation, tour operator, or activity provider.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    <span style={{fontWeight: 'bold', color: color.primary}}>2. Payment Policy:</span>
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    Payment terms may vary and are specified by the accommodation, tour operator, or activity provider at the time of booking. Payments are typically required in full or partially in advance to secure reservations.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    <span style={{fontWeight: 'bold', color: color.primary}}>3. Cancellation and Refund:</span>
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    Cancellation policies vary by supplier and are outlined at the time of booking. Guests are responsible for adhering to these policies, which may involve penalties or fees for cancellations made within a certain period.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    <span style={{fontWeight: 'bold', color: color.primary}}>4. Responsibility:</span>
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    We act solely as intermediaries between the guest and the service provider (accommodation, tour operator, or activity provider). We do not assume liability for any injury, loss, damage, delay, or inconvenience caused by the service providers or any third party.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    <span style={{fontWeight: 'bold', color: color.primary}}>5. Changes and Modifications:</span>
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    Changes to bookings, including amendments to dates, guest names, or other details, may be subject to availability and additional fees as determined by the supplier.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    <span style={{fontWeight: 'bold', color: color.primary}}>6. Force Majeure:</span>
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    We are not liable for changes, delays, or cancellations due to circumstances beyond our control, including but not limited to natural disasters, weather conditions, political unrest, or technical issues.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    <span style={{fontWeight: 'bold', color: color.primary}}>7. Travel Insurance:</span>
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    We strongly recommend that guests obtain comprehensive travel insurance to cover unforeseen circumstances, including trip cancellations, medical emergencies, and loss of personal belongings.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    <span style={{fontWeight: 'bold', color: color.primary}}>8. Compliance:</span>
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    Guests are required to comply with the rules, regulations, and policies of the accommodation, tour operator, or activity provider, as well as any local laws and customs.
                </LBVLabel>
            </Grid>
            <Grid mt={1}>
                <LBVLabel>
                    <span style={{fontWeight: 'bold', color: color.primary}}>By booking with us, you agree to abide by these terms and conditions as outlined by the respective service providers. Please review the specific policies of each service provider before confirming your booking.</span>
                </LBVLabel>
            </Grid>
        </Grid>
    )
}

export default TermsAndConditionApply