import { Grid } from "@mui/material"
import React, { useEffect, useState } from "react"
import { LBVLabel, LBVTitleLabel } from "../../component/label/LBVLabel"
import LBVNav from "../../component/Navbar"
import { color } from "../../helper/constant"


function WhoAreWe ({
    
}) {
    return (
        <Grid>
            <LBVNav />
            <LBVTitleLabel>Who Are We</LBVTitleLabel>
            <Grid>
                <LBVLabel>
                    We are a local travel agency based in Bali, dedicated to providing personalized and memorable travel experiences. Here’s what defines us:
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    <span style={{fontWeight: 'bold', color: color.primary}}>1. Local Expertise:</span> 
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    As natives of Bali, we have an intimate knowledge of the island’s hidden gems, cultural nuances, and best-kept secrets. We leverage this expertise to curate unique travel experiences tailored to your preferences.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    2. <span style={{fontWeight: 'bold', color: color.primary}}>Tailored Services:</span> 
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    Whether you seek adventure, relaxation, or cultural immersion, our team crafts bespoke itineraries and accommodations that align with your interests and desires.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    3. <span style={{fontWeight: 'bold', color: color.primary}}>Customer-Centric Approach:</span>
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    Your satisfaction is our priority. We strive to exceed expectations by delivering exceptional service, transparent communication, and seamless travel arrangements from start to finish.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    4. <span style={{fontWeight: 'bold', color: color.primary}}>Commitment to Quality:</span>
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    We partner with reputable accommodations, tour operators, and service providers to ensure the highest standards of quality, safety, and reliability in every aspect of your journey.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    5. <span style={{fontWeight: 'bold', color: color.primary}}>Support Local:</span>
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    By booking with us, you support the local economy and community initiatives in Bali. We are passionate about sustainable tourism practices that benefit both travelers and locals alike.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    6. <span style={{fontWeight: 'bold', color: color.primary}}>Your Truster Partner:</span>
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    Whether it’s your first visit to Bali or you’re a seasoned traveler, we are here to assist you every step of the way. Let us guide you to discover the essence of Bali through unforgettable experiences.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    Discover the beauty of Bali with us, where every journey is crafted with care and local expertise.
                </LBVLabel>
            </Grid>
        </Grid>
    )
}

export default WhoAreWe