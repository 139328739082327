import { Grid } from "@mui/material"
import React, { useEffect, useState } from "react"
import LBVNav from "../../component/Navbar"
import { LBVLabel, LBVTitleLabel } from "../../component/label/LBVLabel"
import { color } from "../../helper/constant"


function PrivacyPolicy ({
    
}) {
    return (
        <Grid>
            <LBVNav />
            <LBVTitleLabel>Privacy Policy</LBVTitleLabel>
            <Grid>
                <LBVLabel>
                    This Privacy Policy outlines how Local Bali ("we", "us", or "our") collects, uses, discloses, and protects your personal information when you visit our website localbalivillas.com and utilize our services.
                </LBVLabel>
            </Grid>
            <Grid mt={1}>
                <LBVLabel><span style={{ fontWeight: 'bold', color: color.primary}}>1. Information We Collect</span></LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>We may collect the following types of personal information:</LBVLabel>
                <Grid sx={{pl: 2}}>
                    <LBVLabel>
                        <Grid container>
                            <Grid item>
                                • <span style={{ fontWeight: 'bold', color: color.primary }}>Personal Identification Information: </span>Name, email address, phone number, and address.
                            </Grid>
                        </Grid>
                    </LBVLabel>
                </Grid>
                <Grid sx={{pl: 2}}>
                    <LBVLabel>
                        <Grid container>
                            <Grid item>
                                • <span style={{ fontWeight: 'bold', color: color.primary }}>Non-Personal Identification Information: </span>Browser type, Internet Service Provider (ISP), referring/exit pages, and date/time stamps.
                            </Grid>
                        </Grid>
                    </LBVLabel>
                </Grid>
                <Grid sx={{pl: 2}}>
                    <LBVLabel>
                        <Grid container>
                            <Grid item>
                                • <span style={{ fontWeight: 'bold', color: color.primary }}>Cookies and Tracking Technologies: </span>We use cookies to enhance user experience. For more details, please see our Cookie Policy section.
                            </Grid>
                        </Grid>
                    </LBVLabel>
                </Grid>
            </Grid>
            <Grid mt={1}>
                <LBVLabel><span style={{ fontWeight: 'bold', color: color.primary }}>2. Purpose of Data Collection</span></LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>We collect and use your information for the following purposes:</LBVLabel>
                <Grid sx={{pl: 2}}>
                    <LBVLabel>
                        <Grid container>
                            <Grid item>
                                • To provide and maintain our services.
                            </Grid>
                        </Grid>
                    </LBVLabel>
                </Grid>
                <Grid sx={{pl: 2}}>
                    <LBVLabel>
                        <Grid container>
                            <Grid item>
                                • To improve customer service and personalize user experience.
                            </Grid>
                        </Grid>
                    </LBVLabel>
                </Grid>
                <Grid sx={{pl: 2}}>
                    <LBVLabel>
                        <Grid container>
                            <Grid item>
                                • To communicate with you regarding your inquiries and services.
                            </Grid>
                        </Grid>
                    </LBVLabel>
                </Grid>
                <Grid sx={{pl: 2}}>
                    <LBVLabel>
                        <Grid container>
                            <Grid item>
                                • To process transactions and send notifications.
                            </Grid>
                        </Grid>
                    </LBVLabel>
                </Grid>
            </Grid>
            <Grid mt={1}>
                <LBVLabel><span style={{ fontWeight: 'bold', color: color.primary}}>3. Sharing Information</span></LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    We do not sell, trade, or otherwise transfer your personal information to outside parties without your consent, except to trusted third parties who assist us in operating our website or conducting our business, as long as those parties agree to keep this information confidential.
                </LBVLabel>
            </Grid>
            <Grid mt={1}>
                <LBVLabel><span style={{ fontWeight: 'bold', color: color.primary}}>4. Data Security</span></LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    We implement a variety of security measures to maintain the safety of your personal information.
                </LBVLabel>
            </Grid>
            <Grid mt={1}>
                <LBVLabel><span style={{ fontWeight: 'bold', color: color.primary}}>5. Your Rights</span></LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>You have the right to:</LBVLabel>
                <Grid sx={{pl: 2}}>
                    <LBVLabel>
                        <Grid container>
                            <Grid item>
                                • Access your personal information.
                            </Grid>
                        </Grid>
                    </LBVLabel>
                </Grid>
                <Grid sx={{pl: 2}}>
                    <LBVLabel>
                        <Grid container>
                            <Grid item>
                                • Request correction of your personal information.
                            </Grid>
                        </Grid>
                    </LBVLabel>
                </Grid>
                <Grid sx={{pl: 2}}>
                    <LBVLabel>
                        <Grid container>
                            <Grid item>
                                • Request deletion of your personal information.
                            </Grid>
                        </Grid>
                    </LBVLabel>
                </Grid>
                <Grid sx={{pl: 2}}>
                    <LBVLabel>
                        <Grid container>
                            <Grid item>
                                • Withdraw consent to our processing of your personal data.
                            </Grid>
                        </Grid>
                    </LBVLabel>
                </Grid>
            </Grid>
            <Grid mt={1}>
                <LBVLabel><span style={{ fontWeight: 'bold', color: color.primary}}>6. Cookies Policy</span></LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    Cookies are small text files stored on your device to improve user experience. You can choose to accept or decline cookies through your browser settings. However, this may prevent you from taking full advantage of our website.
                </LBVLabel>
            </Grid>
            <Grid mt={1}>
                <LBVLabel><span style={{ fontWeight: 'bold', color: color.primary}}>7. Changes to This Privacy Policy</span></LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new policy on this page. You are advised to review this Privacy Policy periodically for any changes.
                </LBVLabel>
            </Grid>
            <Grid mt={1}>
                <LBVLabel><span style={{ fontWeight: 'bold', color: color.primary}}>8. Contact Us</span></LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    If you have any questions or concerns about this Privacy Policy, please contact us at info@localbalivillas.com
                </LBVLabel>
            </Grid>
        </Grid>
    )
}

export default PrivacyPolicy