import ArrowLeft from '../../assets/arrow_left.png'
import ArrowRight from '../../assets/arrow_right.png'

export const properties = {
    // prevArrow: <button><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff"><path d="M242 180.6v-138L0 256l242 213.4V331.2h270V180.6z"/></svg></button>,
    // nextArrow: <button><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff"><path d="M512 256L270 42.6v138.2H0v150.6h270v138z"/></svg></button>
    prevArrow: <img style={{ marginLeft: -10 }} src={ArrowLeft} width={30} height={30}/>,
    nextArrow: <img style={{ marginRight: -10 }} src={ArrowRight} width={30} height={30}/>
}


export function CustomSlickNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <img className={className} onClick={onClick} style={{ ...style }} 
        src={ArrowRight} width={30} height={30}/>
    );
  }
  
export function CustomSlickPrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <img className={className} onClick={onClick} style={{ ...style }} 
        src={ArrowLeft} width={30} height={30}/>
    );
  }