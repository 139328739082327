import { Grid } from "@mui/material"
import React, { useEffect, useState } from "react"
import LBVNav from "../../component/Navbar"
import { LBVLabel, LBVTitleLabel } from "../../component/label/LBVLabel"
import { color } from "../../helper/constant"

function TermsAndConditionOrder ({
    
}) {
    return (
        <Grid>
            <LBVNav />
            <LBVTitleLabel>Terms and Conditions</LBVTitleLabel>
            <Grid>
                <LBVLabel bold>
                    <span style={{fontWeight: 'bold', color: color.primary}}>1. General Information</span>
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    1.1 These Terms and Conditions ("Terms") govern the use of services and accommodations provided by Local Bali ("PT LOCAL BALI INDONESIA"). By booking and staying at Local Bali, you agree to comply with and be bound by these Terms.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    1.2 Local Bali reserves the right to update or modify these Terms at any time. Changes will be effective upon posting on the Local Bali’s website. It is your responsibility to review the Terms periodically.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel bold>
                    <span style={{fontWeight: 'bold', color: color.primary}}>2. Booking and Reservations</span>
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    2.1 All bookings must be made through Local Bali’s official website or authorized booking platforms. Local Bali does not accept bookings through unauthorized third parties.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    2.2 A valid credit card is required to secure your reservation. Full payment or a deposit may be required at the time of booking, depending on the rate and availability.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    2.3 Reservations are considered confirmed once you receive a confirmation email from Local Bali. It is your responsibility to review the details of your reservation upon receipt of the confirmation.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel bold>
                    <span style={{fontWeight: 'bold', color: color.primary}}>3. Payment Cancellation</span>
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    3.1 Payment policies vary depending on the rate plan chosen. Details regarding payment and cancellation policies will be provided at the time of booking.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    3.2 Cancellation and modification requests must be made in accordance with the cancellation policy of the rate plan selected. Any cancellations or modifications outside the allowed time frame may result in forfeiture of the deposit or payment.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    3.3 In the event of a no-show or early departure, Local Bali reserves the right to charge the total amount of the reservation or as specified in the cancellation policy.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel bold>
                    <span style={{fontWeight: 'bold', color: color.primary}}>4. Check-In and Check-Out</span>
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    4.1 Check-in time is from 3:00 PM onwards, and check-out time is by 12:00 PM (noon). Early check-in and late check-out requests are subject to availability and may incur additional charges.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    4.2 A valid ID or passport is required at check-in. The name on the booking must match the ID presented.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel bold>
                    <span style={{fontWeight: 'bold', color: color.primary}}>5. Additional Charges and Deposits</span>
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    5.1 A security deposit may be required upon arrival. The deposit will be refunded upon check-out, subject to a satisfactory inspection of the accommodation.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    5.2 Additional charges may apply for extra services, such as spa treatments, excursions, and special requests. These charges will be communicated to you prior to confirmation.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel bold>
                    <span style={{fontWeight: 'bold', color: color.primary}}>6. Cancellation by Local Bali</span>
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    6.1 Local Bali reserves the right to cancel a reservation due to unforeseen circumstances, including but not limited to natural disasters, safety concerns, or operational issues. In such cases, you will be notified as soon as possible and a full refund will be provided.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel bold>
                    <span style={{fontWeight: 'bold', color: color.primary}}>7. Additional Charges and Deposits</span>
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    7.1 Guests are expected to conduct themselves in a manner that does not disrupt the comfort or enjoyment of other guests. Local Bali reserves the right to ask any guest who behaves inappropriately to vacate the premises without refund.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    7.2 Pets and animals are not allowed on Local Bali premises unless specifically authorized.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel bold>
                    <span style={{fontWeight: 'bold', color: color.primary}}>8. Liability and Insurance</span>
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    8.1 Local Bali is not liable for any loss, damage, or injury to personal belongings or guests during their stay.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    8.2 Guests are encouraged to have appropriate travel insurance to cover any unforeseen circumstances or accidents.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel bold>
                    <span style={{fontWeight: 'bold', color: color.primary}}>9. Privacy and Data Protection</span>
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    9.1 Personal information provided during the booking process will be used in accordance with Local Bali’s privacy policy. Local Bali is committed to protecting your personal data and ensuring its confidentiality.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel bold>
                    <span style={{fontWeight: 'bold', color: color.primary}}>10. Governing Law</span>
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    10.1 These Terms are governed by and construed in accordance with the laws of the Republic of Indonesia. Any disputes arising from these Terms or your stay at Local Bali shall be subject to the exclusive jurisdiction of the courts in Bali, Indonesia.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel bold>
                    <span style={{fontWeight: 'bold', color: color.primary}}>11. Contact Information</span>
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    11.1 For any questions or concerns regarding these Terms, bookings, or your stay, please contact Local Bali customer service at:
                </LBVLabel>
            </Grid>
            <Grid pl={3}>
                <LBVLabel>
                    Email: cs@localbalivillas.com
                </LBVLabel>
            </Grid>
            <Grid pl={3}>
                <LBVLabel>
                    Phone: +6282340243600
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    By making a reservation and staying at Local Bali, you acknowledge that you have read, understood, and agree to these Terms and Conditions.
                </LBVLabel>
            </Grid>
        </Grid>
    )
}

export default TermsAndConditionOrder