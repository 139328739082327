import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import { KeyboardArrowDown, KeyboardArrowUp, RemoveCircleOutlineOutlined, AddCircleOutlineOutlined } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { validation } from '../../helper/form';


export default function LBVNumberInput({
    value = 1,
    maxNumber,
    onChange,
    isFlex,
    allowZero = false
}) {

    const [ number, setNumber ] = useState(value <= 0 ? value : 1)

    useEffect(() => {
        if (onChange) {
            onChange(number)
        }
    }, [number])

    const arrowUP = () => {
        if (maxNumber) {
            if (number >= maxNumber) { return; }
        }
        setNumber(prev => (
            Number(prev) + 1
        ))
    }

    const arrowDown = () => {
        if (number <= 1 && !allowZero) { return; }
        else { if (number <= 0) { return; } }
        setNumber(prev => (
            Number(prev) - 1
        ))
    }

    return (
        <Paper
            component="form"
            sx={{ p: '2px 2px', display: 'flex', alignItems: 'center'}}
        >
            {
                isFlex ?
                <>
                    <Grid container xs={12}>
                        <Grid item p={0} xs={3} textAlign={"center"}>
                            <IconButton sx={{ width: 30, height: 10 }} onClick={() => {
                                arrowDown()
                            }}>
                                <RemoveCircleOutlineOutlined />
                            </IconButton>
                        </Grid>
                        <Grid item p={0} xs={6}>
                            <InputBase
                                sx={{ flex: 1 }}
                                placeholder=""
                                value={number}
                                inputProps={{ style: {textAlign: 'center'} }}
                                onKeyDown={(e) => {
                                    if(e.key == 'Enter') {
                                        e.preventDefault()
                                        setNumber(number)
                                        e.target.blur()
                                    }
                                }}
                                onChange={(e) => {
                                    if (!validation.isNumber(e.currentTarget.value)) { return; }
                                    if (e.currentTarget.value == "0" && !allowZero) { return; }
                                    if (maxNumber && (e.currentTarget.value > maxNumber)) { return; }
                                    setNumber(e.currentTarget.value)
                                }}
                            />
                        </Grid>
                        <Grid item p={0} xs={3} textAlign={"center"}>
                            <IconButton sx={{ width: 30, height: 10 }} onClick={() => {
                                arrowUP()
                            }}>
                                <AddCircleOutlineOutlined />
                            </IconButton>
                        </Grid>
                    </Grid>
                </>
                :
                <>
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder=""
                        value={number}
                        onKeyDown={(e) => {
                            if(e.key == 'Enter') {
                                e.preventDefault()
                                setNumber(number)
                                e.target.blur()
                            }
                        }}
                        onChange={(e) => {
                            if (!validation.isNumber(e.currentTarget.value)) { return; }
                            if (e.currentTarget.value == "0") { return; }
                            if (maxNumber && (e.currentTarget.value > maxNumber)) {
                                return;
                            }
                            setNumber(e.currentTarget.value)
                        }}
                    />
                    <Grid container xs={4}>
                        <Grid item p={0} pb={1} xs={12}>
                            <IconButton sx={{ width: 30, height: 10 }} onClick={() => {
                                arrowUP()
                            }}>
                                <KeyboardArrowUp />
                            </IconButton>
                        </Grid>
                        <Grid item p={0} xs={12}>
                            <IconButton sx={{ width: 30, height: 10 }} onClick={() => {
                                arrowDown()
                            }}>
                                <KeyboardArrowDown />
                            </IconButton>
                        </Grid>
                    </Grid>
                </>
            }
        </Paper>
    );
}