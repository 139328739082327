import React, { useState } from "react";
import { Dialog, Grid, Tooltip } from "@mui/material";
import { Slide } from "react-slideshow-image";
import { amenitiesData } from "../../helper/amenities";
import { LBVLabel } from "../../component/label/LBVLabel";
import { isPhone } from "../../helper/constant";
import { properties } from "../../component/slider/SlideButton";
import LBVGallery from "../../component/Lightbox";
import ListImage from "../../component/ListImage";

import lbvIconAdult from "../../assets/lbv_icon_adult.png";
import lbvIconKids from "../../assets/lbv_icon_kids.png";
import lbvIconPool from "../../assets/lbv_icon_pool.png";
import lbvIconRoom from "../../assets/lbv_icon_room.png";

function RoomDetail ({
    data
}) {

    const [ open, setOpen ] = useState(false)

    const checkAmenitiesExist = (value) => {
        let isExist = false
        for (let i = 0; i < data.amenities.length; i ++) {
            let amenities = data.amenities[i]
            let amenitiesName = value.amenities.map(v => { return v.name })
            if (amenitiesName.indexOf(amenities) >= 0) { isExist = true; break; }
        }
        return isExist
    }

    return (
        <Grid container justifyContent={"flex-start"} alignContent={"flex-start"} alignItems={"flex-start"}>
            <Grid xs={12} lg={6} mr={3}>
                <Slide
                    {...properties}
                    // indicators={false}
                    duration={3000}
                    autoplay={true}
                    transitionDuration={200}
                >
                    {
                        data.propertyImage.map( (value ) => {
                            return (
                                <img src={value} width={'100%'} style={{
                                    maxHeight: window.innerWidth >= 444 ? window.innerWidth/1.78 : window.innerWidth / 1.78,
                                    borderRadius: 10
                                }}/>
                            )
                        })
                    }
                </Slide>
                <Grid container xs={12}>
                    <Grid item xs={6}>
                    </Grid>
                    <Grid item xs={6} textAlign={"right"}>
                        <LBVLabel style={{
                            cursor: 'pointer'
                        }}><span onClick={() => { setOpen(true) } }>View All Photos</span></LBVLabel>
                    </Grid>
                </Grid>
            </Grid>
            <Grid xs container sx={{
                overflowX: 'auto',
                maxHeight: '100vh'
            }}>
                {
                    data.maximumGuest && 
                    <Grid item xs={4} mb={1}>
                        <Grid item>
                            <LBVLabel>{"Maximum Guest"}</LBVLabel>
                        </Grid>
                        {
                            (data.maximumGuest.adult && data.maximumGuest.adult > 0) &&
                            <LBVLabel><img width={20} height={20} src={lbvIconAdult} />{data.maximumGuest.adult}</LBVLabel>
                        }
                        {
                            (data.maximumGuest.child && data.maximumGuest.child > 0) &&
                            <LBVLabel><img width={20} height={20} src={lbvIconKids} />{data.maximumGuest.child}</LBVLabel>
                        }
                    </Grid>
                }
                {
                    data.poolSize && 
                    <Grid item xs={4} mb={1}>
                        <Grid item>
                            <LBVLabel>{"Pool Size"}</LBVLabel>
                        </Grid>
                        <LBVLabel><img width={20} height={20} src={lbvIconPool} />{data.poolSize}</LBVLabel>
                    </Grid>
                }
                {
                    data.roomSize && 
                    <Grid item xs={4} mb={1}>
                        <Grid item>
                            <LBVLabel>{"Room / Villa Size"}</LBVLabel>
                        </Grid>
                        <LBVLabel><img width={20} height={20} src={lbvIconRoom} />{data.roomSize}</LBVLabel>
                    </Grid>
                }
                {
                    data.amenities.length > 0 && 
                    <Grid item container xs={12} spacing={0.5}>
                        {/* check amenities */}
                        {
                            amenitiesData.map( (value, index) => {
                                let amenitiesExist = checkAmenitiesExist(value)
                                if (!amenitiesExist) { return; }
                                return (
                                    <Grid item container xs={12} lg={12} justifyContent={"flex-start"} alignContent={"flex-start"} alignItems={"baseline"}>
                                        <Grid item mb={0.5}>
                                            <LBVLabel style={{
                                                fontWeight: 500
                                            }}>{value.category}</LBVLabel>
                                        </Grid>
                                        <Grid item container xs={12} alignContent={"flex-start"} alignItems={"flex-start"} spacing={0.5}>
                                            {
                                                value.amenities.map( (v, index) => {
                                                    if (data.amenities.indexOf(v.name) >= 0 ) {
                                                        return (
                                                            <Grid item>
                                                                <Grid container p={0.5} sx={{
                                                                    border: 'solid 1px #957c64',
                                                                    borderRadius: 2,
                                                                    fontSize: 11,
                                                                    cursor: 'pointer',
                                                                }} justifyItems={"center"} alignItems={"center"}>
                                                                    {v.icon && <img src={v.icon} width={12} height={12} style={{marginRight: 2}}/>}
                                                                    {v.name}
                                                                </Grid>
                                                            </Grid>
                                                        )
                                                    }
                                                })
                                            }
                                        </Grid>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                }
            </Grid>
            {/* <LBVGallery
                open={open}
                setOpen={setOpen}
                images={data.propertyImage}
            /> */}
             <Dialog
                fullWidth={true}
                fullScreen={true}
                open={open}
                onClose={setOpen}
            >
                <ListImage
                    showImageModel={open}
                    setShowImageModel={setOpen}
                    setOpenImageDetail={() => {}}
                    propertyImages={data.propertyImage}
                />
            </Dialog>
        </Grid>
    )
}

export default RoomDetail