import { Grid } from "@mui/material"
import React, { useEffect, useState } from "react"
import LBVNav from "../../component/Navbar"
import { LBVLabel, LBVTitleLabel } from "../../component/label/LBVLabel"
import { color } from "../../helper/constant"

function ContactUs ({
    
}) {
    return (
        <Grid>
            <LBVNav />
            <LBVTitleLabel>Contact Us</LBVTitleLabel>
            <Grid>
                <LBVLabel>
                    For any legal inquiries or to verify our credentials, please contact us directly. We are happy to provide any additional information you may require.
                </LBVLabel>
            </Grid>
            <Grid sx={{pl: 2}}>
                <LBVLabel>
                    <Grid container>
                        <Grid item xs={1}>
                            <span style={{fontWeight: 'bold', color: color.primary}}>Email</span> 
                        </Grid>    
                        <Grid item style={{
                            cursor: 'pointer'
                        }} onClick={() => {
                            window.open('mailto:info@localbalivillas.com?subject=Subject&body=', "_blank")
                        }}>
                            : info@localbalivillas.com
                        </Grid>
                    </Grid>
                </LBVLabel>
            </Grid>
            <Grid sx={{pl: 2}}>
                <LBVLabel>
                    <Grid container>
                        <Grid item xs={1}>
                            <span style={{fontWeight: 'bold', color: color.primary}}>Phone</span> 
                        </Grid>    
                        <Grid item style={{
                            cursor: 'pointer'
                        }} onClick={() => {
                            window.location.href = "tel:+6282340243600"
                        }}>
                            : +62 823-4024-3600
                        </Grid>
                    </Grid>
                </LBVLabel>
            </Grid>
            <Grid sx={{pl: 2}}>
                <LBVLabel>
                    <Grid container>
                        <Grid item xs={1}>
                            <span style={{fontWeight: 'bold', color: color.primary}}>Address</span> 
                        </Grid>    
                        <Grid item style={{
                            cursor: 'pointer'
                        }} onClick={() => {
                            window.open("https://maps.google.com/?q=-8.666457170367105,115.22611985571564", "_blank")
                        }}>
                            : Jl. Cok Agung Tresna - Renon, Komplek Pertokoan Griya Alamanda No. 12 A lt1 Denpasar - Bali
                        </Grid>
                    </Grid>
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    Thank you for choosing Local Bali Travel Agent. We look forward to serving you and making your trip to Bali an unforgettable experience.
                </LBVLabel>
            </Grid>
        </Grid>
    )
}

export default ContactUs