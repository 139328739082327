import React from "react"

import Main from "./pages/Main"
import Place from "./pages/Place/Place"
import PlaceList from "./pages/Place/PlaceList"

// About Us
import WhoAreWe from "./pages/AboutUs/WhoAreWe"
import WhyBookWithUs from "./pages/AboutUs/WhyBookWithUs"
import BestPriceGuarantee from "./pages/AboutUs/BestPriceGuarantee"
import TermsAndConditionApply from "./pages/AboutUs/TermsAndConditionApply"
import FAQ from "./pages/AboutUs/FAQ"
import LegalInformation from "./pages/AboutUs/LegalInformation"
import ComplianceAndStandard from "./pages/AboutUs/ComplianceAndStandard"
import ClientProtection from "./pages/AboutUs/ClientProtection"
import ContactUs from "./pages/AboutUs/ContactUs"
import SupportInfo from "./pages/AboutUs/SupportInfo"
import PrivacyPolicy from "./pages/AboutUs/PrivacyPolicy"
import TermsAndConditionOrder from "./pages/AboutUs/TermsAndConditionOrder"

const routes : any[] = [
    {
        name: "Home",
        route: "/",
        component: <Main />,
    },
    {
        name: "Properties",
        route: "/properties",
        component: <PlaceList />,
    },
    {
        name: "Villas",
        route: "/villas/:id",
        component: <Place />,
    },
    {
        name: "Hotels",
        route: "/hotels/:id",
        component: <Place />,
    },
    {
        name: "Resorts",
        route: "/resorts/:id",
        component: <Place />,
    },
    {
        name: "Resorts",
        route: "/bamboo_house/:id",
        component: <Place />,
    },
    {
        name: "Experiences",
        route: "/",
        component: <Main />,
    },
    {
        name: "Transport",
        route: "/",
        component: <Main />,
    },
    {
        name: "About Us",
        route: "/about-us/who-are-we",
        component: <WhoAreWe />,
    },
    {
        name: "About Us",
        route: "/about-us/why-book-with-us",
        component: <WhyBookWithUs />,
    },
    {
        name: "About Us",
        route: "/about-us/best-price-guarantee",
        component: <BestPriceGuarantee />,
    },
    {
        name: "About Us",
        route: "/about-us/terms-condition",
        component: <TermsAndConditionApply />,
    },
    {
        name: "About Us",
        route: "/about-us/faq",
        component: <FAQ />,
    },
    {
        name: "About Us",
        route: "/about-us/legal-information",
        component: <LegalInformation />,
    },
    {
        name: "About Us",
        route: "/about-us/compliance-standard",
        component: <ComplianceAndStandard />,
    },
    {
        name: "About Us",
        route: "/about-us/client-protection",
        component: <ClientProtection />,
    },
    {
        name: "About Us",
        route: "/about-us/contact-us",
        component: <ContactUs />,
    },
    {
        name: "About Us",
        route: "/about-us/24-7-support",
        component: <SupportInfo />,
    },
    {
        name: "About Us",
        route: "/about-us/privacy-policy",
        component: <PrivacyPolicy />,
    },
    {
        name: "Terms and Condition",
        route: "/about-us/terms-condition-order",
        component: <TermsAndConditionOrder />,
    },
    {
        name: "Guest Review",
        route: "/",
        component: <Main />,
    }
]

export default routes