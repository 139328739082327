import { Grid } from "@mui/material"
import React, { useEffect, useState } from "react"
import LBVNav from "../../component/Navbar"
import { LBVLabel, LBVTitleLabel } from "../../component/label/LBVLabel"
import { color } from "../../helper/constant"


function SupportInfo ({
    
}) {
    return (
        <Grid>
            <LBVNav />
            <LBVTitleLabel>24/7 Support Team</LBVTitleLabel>
            <Grid>
                <LBVLabel>
                    <span style={{ fontWeight: 'bold', color: color.primary}}>Around-the-Clock Assistance</span>
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    At Local Bali Travel Agent, we understand that travel plans can change at any moment, and unexpected situations may arise. That's why we offer 24/7 support to ensure that our clients receive prompt and reliable assistance whenever they need it.
                </LBVLabel>
            </Grid>
            <Grid mt={1}>
                <LBVLabel><span style={{ fontWeight: 'bold', color: color.primary}}>What We Offer</span></LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    <span style={{ fontWeight: 'bold', color: color.primary}}>Immediate Response : </span>
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    Our dedicated support team is available around the clock to respond to your inquiries. Whether you have a question about your booking, need assistance with transportation, or require help with any other travel-related matter, we are here to assist you.
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    <span style={{ fontWeight: 'bold', color: color.primary}}>Emergency Assistance :</span> 
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    If you encounter any emergencies during your stay in Bali, our team is ready to provide immediate support. We will help you navigate any challenges and find quick solutions.
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    <span style={{ fontWeight: 'bold', color: color.primary}}>Local Expertise :</span> 
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    Our team comprises local experts who have in-depth knowledge of Bali. They can offer valuable insights, recommend the best places to visit, and provide tips to enhance your travel experience. We are committed to making your stay in Bali as enjoyable and memorable as possible.
                </LBVLabel>
            </Grid>
            <Grid mt={1}>
                <LBVLabel><span style={{ fontWeight: 'bold', color: color.primary}}>How to Reach Us</span></LBVLabel>
            </Grid>
            <Grid sx={{pl: 2}}>
                <LBVLabel>
                    <Grid container>
                        <Grid item xs={1}>
                            <span style={{fontWeight: 'bold', color: color.primary}}>Phone</span> 
                        </Grid>    
                        <Grid item>
                            : You can call us anytime at +62 823-4024-3600 for immediate assistance.
                        </Grid>
                    </Grid>
                </LBVLabel>
            </Grid>
            <Grid sx={{pl: 2}}>
                <LBVLabel>
                    <Grid container>
                        <Grid item xs={1}>
                            <span style={{fontWeight: 'bold', color: color.primary}}>Email</span> 
                        </Grid>    
                        <Grid item>
                            : Send your queries to support@localbalitravelagent.com, and we will respond promptly.
                        </Grid>
                    </Grid>
                </LBVLabel>
            </Grid>
            <Grid sx={{pl: 2}}>
                <LBVLabel>
                    <Grid container>
                        <Grid item xs={1}>
                            <span style={{fontWeight: 'bold', color: color.primary}}>WhatsApp</span> 
                        </Grid>    
                        <Grid item>
                            : For quick messaging support, reach us on WhatsApp at +62 823-4024-3600.
                        </Grid>
                    </Grid>
                </LBVLabel>
            </Grid>
            <Grid sx={{pl: 2}}>
                <LBVLabel>
                    <Grid container>
                        <Grid item xs={1}>
                            <span style={{fontWeight: 'bold', color: color.primary}}>Live Chat</span> 
                        </Grid>    
                        <Grid item>
                            : Visit our website and use the live chat feature for real-time assistance.
                        </Grid>
                    </Grid>
                </LBVLabel>
            </Grid>
            <Grid mt={1}>
                <LBVLabel><span style={{ fontWeight: 'bold', color: color.primary}}>Client Satisfaction</span></LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    Our top priority is your satisfaction and peace of mind. By providing 24/7 support, we aim to offer unparalleled service and ensure that you have a smooth and enjoyable travel experience. Whether its day or night, you can count on Local Bali Travel Agent to be there for you.
                    Thank you for trusting Local Bali Travel Agent. We are committed to making your Bali adventure unforgettable, with support available whenever you need it.
                </LBVLabel>
            </Grid>
        </Grid>
    )
}

export default SupportInfo