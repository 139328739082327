import React from "react"
import { Grid } from "@mui/material"
import { LBVLabel, LBVTitleLabel } from "../label/LBVLabel"
import { isPhone } from "../../helper/constant"

function DialogHeader ({
    title,
    closeDialog,
    fontSize = isPhone() ? 15 : 24
}) {
    return (
        <Grid item xs={12} container pb={1} alignItems={"center"} >
            <Grid xs={11} item>
                {/* <TCTitleLabel>{title}</TCTitleLabel> */}
                <LBVTitleLabel bold style={{
                    fontSize: fontSize
                }}>{title}</LBVTitleLabel>
            </Grid>
            <Grid xs={1} item textAlign={"right"} style={{ cursor: 'pointer' }}>
                <span onClick={closeDialog}>
                    <LBVLabel bold style={{color: 'black'}}>X</LBVLabel>
                </span>
            </Grid>
        </Grid>
    )
}

export default DialogHeader