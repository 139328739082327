import React, { useEffect, useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";

function LBVGallery ({
    open,
    setOpen,
    images
}) {

    const [ imageList, setImageList ] = useState([])
    
    useEffect(() => { 
        let imgList = []
        for (let i = 0; i < images.length; i ++) {
            imgList.push({
                src: images[i]
            })
        }
        setImageList([...imgList])
    }, [images])

    return (
        <Lightbox
            open={open}
            close={() => setOpen(false)}
            plugins={[Zoom,Fullscreen]}
            slides={[...imageList]}
            zoom={{
                scrollToZoom:true,
                maxZoomPixelRatio:5
            }}
        />
    )
}

export default LBVGallery