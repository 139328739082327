import React, { useEffect, useState } from "react";

import {
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Collapse,
    Container
  } from "shards-react";

import landscapeLBV from "../assets/lbv_primary.png";
import { Grid } from "@mui/material";
import routes from "../routes";

export default function LBVNav() {

  const [ collapseOpen, setCollapseOpen] = useState(false)
  const [ dropDownOpen, setDropdownOpen ] = useState(false)

  const toggleDropdown = () => {
    setDropdownOpen(!dropDownOpen)
  }

  const toogleNavbar = () => {
    setCollapseOpen(!collapseOpen)
  }

  return (
    <Navbar type="dark" expand="md" style={{
      // background: '#957c6466',
      // color: '#957c6466',
      position: 'sticky',
      paddingLeft: 0
    }}>
        <Grid container xs={12} md={12} mt={1} textAlign={"center"}>
          <NavbarBrand href="/">
            <img src={landscapeLBV} style={{
              width: 150,
              height: 'auto'
            }}/>
          </NavbarBrand>
        </Grid>
        {/* <NavbarToggler onClick={toogleNavbar} />

        <Collapse open={collapseOpen} navbar>
          <Grid container xs={12} justifyContent={"left"} spacing={3}
            sx={{
              cursor: 'pointer'
            }}
          >
            {
              routes.map(value => {
                return (
                  <Grid item textAlign={"left"}>
                    {value.name}
                  </Grid>
                )
              })
            }
          </Grid>
        </Collapse> */}
    </Navbar>
  );
}