export const LOCALSTORAGECONSTANT = {
    LBV_USER : "lbvUser",
    LBV_SEARCH_OBJ : "lbvSearchObj",
}

export const childrenAge = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
    { value: 7, label: "7" },
    { value: 8, label: "8" },
    { value: 9, label: "9" },
    { value: 10, label: "10" }
]

export const roomOptions = [{
    value: "1", label: "1 Room",
}, {
    value: "2", label: "2 Room(s)",
}, {
    value: "3", label: "3 Room(s)",
}, {
    value: "4", label: "4 Room(s)",
}, {
    value: "5", label: "5 Room(s)",
}, {
    value: "6", label: "6 Room(s)",
}, {
    value: "7", label: "7 Room(s)",
}, {
    value: "8", label: "8 Room(s)",
}, {
    value: "9", label: "9 Room(s)",
}, {
    value: "10", label: "10+ Room(s)",
}]

export const color = {
    // Primary used color
    primary: "#957c64",
    green: "#536942",
    // Other Color
    green_success: "#4DBD74",
    pink: 'red'
}

export const timeStringFormat = (e) => {
    let value = e
    return `${value < 10 ? `0${value}` : value}:00`
}

export const iOS = () =>{
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

export const isPhone = (width = 1000) => {
    return window.innerWidth < width
}

export const genderOptions = [
    { value: "any" , label: "Any"},
    { value: "male" , label: "Male"},
    { value: "female" , label: "Female"},
]

export const accomodationOptions = [
    {
        label: "Hotel",
        value: "hotel"
    },
    {
        label: "Villas",
        value: "villa"
    }
]

export const titleOptions = [
    { label: "Mr/Mrs", value: "Mr/Mrs" },
    { label: "Ms", value: "Ms" }
]

export const hoursOptions = [
    { label: "00:00", value : "00:00" },
    { label: "01:00", value : "01:00" },
    { label: "02:00", value : "02:00" },
    { label: "03:00", value : "03:00" },
    { label: "04:00", value : "04:00" },
    { label: "05:00", value : "05:00" },
    { label: "06:00", value : "06:00" },
    { label: "07:00", value : "07:00" },
    { label: "08:00", value : "08:00" },
    { label: "09:00", value : "09:00" },
    { label: "10:00", value : "10:00" },
    { label: "11:00", value : "11:00" },
    { label: "12:00", value : "12:00" },
    { label: "13:00", value : "13:00" },
    { label: "14:00", value : "14:00" },
    { label: "15:00", value : "15:00" },
    { label: "16:00", value : "16:00" },
    { label: "17:00", value : "17:00" },
    { label: "18:00", value : "18:00" },
    { label: "19:00", value : "19:00" },
    { label: "20:00", value : "20:00" },
    { label: "21:00", value : "21:00" },
    { label: "22:00", value : "22:00" },
    { label: "23:00", value : "23:00" },
]