import { LOCALSTORAGECONSTANT } from '../helper/constant'; 
import { reactLocalStorage } from "reactjs-localstorage";

export const setUserInfo = function(data) {
    reactLocalStorage.setObject(LOCALSTORAGECONSTANT.LBV_USER, data);
    return true
}
export const getUserInfo = checkUserInfo()
export const deleteUserInfo = function() { reactLocalStorage.remove(LOCALSTORAGECONSTANT.LBV_USER); return true}

export const setSearchObj = function(data) {
    reactLocalStorage.setObject(LOCALSTORAGECONSTANT.LBV_SEARCH_OBJ, data);
    return true
}
export const getSearchObj = reactLocalStorage.get(LOCALSTORAGECONSTANT.LBV_SEARCH_OBJ)
export const deleteSearchObj = function() { reactLocalStorage.remove(LOCALSTORAGECONSTANT.LBV_SEARCH_OBJ); return true}

function checkUserInfo () {
    if (reactLocalStorage.get(LOCALSTORAGECONSTANT.LBV_USER) == undefined) { return null }
    if (reactLocalStorage.get(LOCALSTORAGECONSTANT.LBV_USER) == "undefined") { return null }
    return reactLocalStorage.get(LOCALSTORAGECONSTANT.LBV_USER) || null
}