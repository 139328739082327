import { Grid } from "@mui/material"
import React, { useEffect, useState } from "react"
import LBVNav from "../../component/Navbar"
import { LBVLabel, LBVTitleLabel } from "../../component/label/LBVLabel"
import { color } from "../../helper/constant"

function ClientProtection ({
    
}) {
    return (
        <Grid>
            <LBVNav />
            <LBVTitleLabel>Client Protection</LBVTitleLabel>
            <Grid>
                <LBVLabel>
                    <span style={{fontWeight: 'bold', color: color.primary}}>Transparent Pricing:</span>
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    We provide clear and transparent pricing for all our services. There are no hidden fees or unexpected charges. All costs are communicated upfront, and any additional fees are explained before booking.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    <span style={{fontWeight: 'bold', color: color.primary}}>Secure Transactions:</span>
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    We use secure payment systems to protect your financial information. Our payment methods comply with international security standards to ensure your data is safe.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    <span style={{fontWeight: 'bold', color: color.primary}}>Customer Support:</span>
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel>
                    Our customer support team is available 24/7 to assist with any inquiries, concerns, or issues that may arise during your trip. We are dedicated to providing prompt and effective assistance to ensure your travel experience is seamless.
                </LBVLabel>
            </Grid>
        </Grid>
    )
}

export default ClientProtection