import { client, errorValidation } from "./service";

export const getLocation = async function () {
  try {
    let response = await client.get(`/region/location`);
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data, error: null };
  } catch (e) {
    // return { error: e.response.data.errorCode }
    return { data: null, ...errorValidation(e) }
  }
}