import React, { useEffect, useState } from "react"

import {
    FacebookShareButton,
    FacebookIcon,
    FacebookMessengerShareButton,
    FacebookMessengerIcon,
    WhatsappShareButton,
    WhatsappIcon,
  } from "react-share";
import { Card } from "shards-react";
import { LBVInput } from "../input/LBVInput";
import { Grid } from "@mui/material";

import { FaCopy } from "react-icons/fa6";

function ShareLinkComponent (
    props: {
        shareUrl: any
    }
) {

    const { shareUrl } = props
    const [ copied, setCopied ] = useState(false)
    const [ alertTitle, setAlertTitle ] = useState("Copied")

    const copyToClipboard = (data: any, message = 'Value has been copied') => {
        if (navigator && navigator.clipboard) {
          navigator.clipboard.writeText(data);
        }
        setCopied(true)
        setAlertTitle("Copied")
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCopied(false);
          }, 2000);
    
        return () => clearInterval(intervalId);
      }, [])

    return (
        <Card>
            <Grid p={1}>
                <Grid item container xs p={0.5} sx={{
                    background: '#f5f5f5',
                    borderRadius: 2,
                    cursor: 'pointer'
                }}>
                    <Grid container>
                        <Grid item mt={0.5} mr={1} onClick={() => {
                            copyToClipboard(shareUrl)
                        }}>
                            <FaCopy/>
                            {/* <PlaceIcon sx={{
                                color: '#957c64'
                            }}/> */}
                        </Grid>
                        <Grid item xs>
                            <LBVInput disabled styles={{
                                width: 200,
                            }} value={shareUrl}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={1} mt={0.5}>
                    <Grid item>
                        <FacebookShareButton url={shareUrl}>
                            <FacebookIcon size={32} round={true}/>
                        </FacebookShareButton>
                    </Grid>
                    {/* <Grid item>
                        <FacebookMessengerShareButton url={shareUrl}>
                            <FacebookMessengerIcon size={32} round={true}/>
                        </FacebookMessengerShareButton>
                    </Grid> */}
                    <Grid item>
                        <WhatsappShareButton url={shareUrl}>
                            <WhatsappIcon size={32} round={true}/>
                        </WhatsappShareButton>
                    </Grid>
                </Grid>
            </Grid>
            {/* {(shareCount) => <span className="myShareCountWrapper">{shareCount}</span>} */}
            {/* </FacebookShareCount> */}
        </Card>
    )
}

export default ShareLinkComponent