import { Grid } from "@mui/material"
import React, { useEffect, useState } from "react"
import LBVNav from "../../component/Navbar"
import { LBVLabel, LBVTitleLabel } from "../../component/label/LBVLabel"
import { color } from "../../helper/constant"

function FAQ ({
    
}) {
    return (
        <Grid>
            <LBVNav />
            <LBVTitleLabel>Frequently Asked Questions (FAQ) about Local Bali Travel Agent</LBVTitleLabel>
            <Grid>
                <LBVLabel style={{fontWeight: 'bold', color: color.primary}}>
                    1. What services do you offer as a local Bali travel agent?
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    We offer a range of services including villa bookings, tour packages, airport transfers, and activity arrangements across Bali.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel style={{fontWeight: 'bold', color: color.primary}}>
                    2. How can I contact you for bookings or inquiries?
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    You can reach us through our website contact form, email, or phone number provided on our contact page.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel style={{fontWeight: 'bold', color: color.primary}}>
                    3. Are your prices competitive with other travel agents?
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    Yes, we strive to offer competitive pricing for our services while maintaining high-quality standards.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel style={{fontWeight: 'bold', color: color.primary}}>
                    4. Can you arrange customized tour packages?
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    Absolutely! We specialize in tailor-made tour packages based on your preferences and interests.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel style={{fontWeight: 'bold', color: color.primary}}>
                    5. Do you provide airport pick-up and drop-off services?
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    Yes, we offer airport transfers to and from your accommodation as part of our services.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel style={{fontWeight: 'bold', color: color.primary}}>
                    6. What are the payment options available for booking with you?
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    We accept various payment methods including credit/debit cards, bank transfers, and online payment platforms.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel style={{fontWeight: 'bold', color: color.primary}}>
                    7. How far in advance should I book my accommodations or tours?
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    It's recommended to book at least a few weeks in advance, especially during peak seasons, to ensure availability.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel style={{fontWeight: 'bold', color: color.primary}}>
                    8. Do you offer 24-hour customer support?
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    Yes, we have dedicated customer support available to assist you around the clock with any queries or issues.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel style={{fontWeight: 'bold', color: color.primary}}>
                    9. Can you assist with special requests such as birthday celebrations or romantic surprises?
                </LBVLabel>
            </Grid>
            <Grid pl={2}>
                <LBVLabel>
                    Certainly! We can arrange special requests to make your trip memorable, including celebrations and surprises.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel style={{fontWeight: 'bold', color: color.primary}}>
                    10. Are there any additional charges or hidden fees I should be aware of?
                </LBVLabel>
            </Grid>
            <Grid pl={3}>
                <LBVLabel>
                    We strive for transparency, and any additional charges or fees will be clearly communicated to you before booking.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel style={{fontWeight: 'bold', color: color.primary}}>
                    11. How can I cancel or modify my booking?
                </LBVLabel>
            </Grid>
            <Grid pl={3}>
                <LBVLabel>
                    Please refer to our cancellation policy outlined on our website or contact us directly for assistance with modifications.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel style={{fontWeight: 'bold', color: color.primary}}>
                    12. Are your tour guides and drivers knowledgeable and fluent in English?
                </LBVLabel>
            </Grid>
            <Grid pl={3}>
                <LBVLabel>
                    Yes, our tour guides and drivers are experienced, knowledgeable about Bali's attractions, and fluent in English.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel style={{fontWeight: 'bold', color: color.primary}}>
                    13. Can you recommend must-visit places in Bali?
                </LBVLabel>
            </Grid>
            <Grid pl={3}>
                <LBVLabel>
                    Absolutely! We can provide recommendations based on your interests, whether it's beaches, cultural sites, or adventure activities.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel style={{fontWeight: 'bold', color: color.primary}}>
                    14. Do you offer travel insurance options?
                </LBVLabel>
            </Grid>
            <Grid pl={3}>
                <LBVLabel>
                    While we don't provide travel insurance directly, we can recommend reputable providers and assist with arrangements.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel style={{fontWeight: 'bold', color: color.primary}}>
                    15. How can I provide feedback about my experience with your services?
                </LBVLabel>
            </Grid>
            <Grid pl={3}>
                <LBVLabel>
                    We welcome your feedback! You can leave a review on our website or social media platforms, or contact us directly.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel style={{fontWeight: 'bold', color: color.primary}}>
                    16. How do I know if my booking is confirmed?
                </LBVLabel>
            </Grid>
            <Grid pl={3}>
                <LBVLabel>
                    Once your booking is confirmed, you will receive a confirmation email with all the details, including payment information.
                </LBVLabel>
            </Grid>
            <Grid>
                <LBVLabel style={{fontWeight: 'bold', color: color.primary}}>
                    Feel free to contact us for any other questions or specific inquiries not covered in our FAQ section.
                </LBVLabel>
            </Grid>
        </Grid>
    )
}

export default FAQ