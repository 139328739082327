import React from "react"
import { Typography } from "@mui/material";
import { isPhone } from "../../helper/constant";

function LBVTitleLabel ({
    variant = {},
    children = React.ReactNode,
    style = {},
    subtitle = false,
    bold = false,
    inheritColor = false,
    onClick = () => {}
}) {
    const styles  = {
        // fontSize: 20, fontWeight: 30,
        fontSize: 18, fontWeight: 500,
        ...style,
    }
    return (
        <Typography variant={variant} color={inheritColor ? "inherit" : "primary"}
            style={{...styles}} onClick={onClick}>
            {children}
        </Typography>
    )
}

function LBVLabel ({
    variant = {},
    children = React.ReactNode,
    style = {},
    subtitle = false,
    bold = false,
    inheritColor = false,
    onClick = () => {}
}) {

    const subtitleFontSize = isPhone() ? 11 : 13
    const displayFontSize = isPhone() ? 12 : 14

    const styles  = {
        // fontSize: 14, fontWeight: 30,
        fontSize: subtitle ? subtitleFontSize : displayFontSize, fontWeight: bold ? 500 : 400,
        ...style,
    }
    return (
        <Typography variant={variant} color={inheritColor ? "inherit" : "black"}
            style={{...styles}} onClick={onClick}>
            {children}
        </Typography>
    )
}

export {
    LBVLabel,
    LBVTitleLabel
}