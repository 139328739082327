
import React, { useEffect, useState } from "react";
import { Grid, Icon, Button } from "@mui/material";
import { LBVLabel, LBVTitleLabel } from "../../label/LBVLabel";

import PlaceIcon from '@mui/icons-material/Place';

import _ from 'lodash';

function Region (
    props: { 
        onSelect: (e: any) => void,
        toogle: () => void,
        locationData: any []
    },
) {

    const { onSelect, toogle, locationData} = props

    return (
        <>
            <LBVTitleLabel bold>Select your destination</LBVTitleLabel>
            <Grid container sx={{
                width: 300,
                maxHeight: 300,
                overflow: 'auto'
            }}>
                {
                    locationData.map(value => {
                        return (
                            <Grid p={2} pl={0} xs={12} item container onClick={(e) => {
                                e.stopPropagation()
                                onSelect(value)
                            }} style={{
                                cursor: 'pointer'
                            }}>
                                <Grid item mr={1}>
                                    <PlaceIcon sx={{
                                        color: '#957c64'
                                    }}/>
                                </Grid>
                                <Grid item mt={0.2}>
                                    {value.locationName}
                                </Grid>
                            </Grid>
                        )
                    })
                }
            </Grid>  
        </>
    )
}

export default Region